import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, Sector, ResponsiveContainer } from 'recharts';
import React, { useState } from 'react';
import { Flex } from '@adobe/react-spectrum';
import { BaseDeckPane } from './BaseDeckPane';
import isMobile from 'ismobilejs';

export function ArchetypesPane(props) {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const archetypesChartData = [];
    let radianPosition = 0;
    const parseOrder = ['MIDRANGE', 'CONTROL', 'COMBO'];
    const mobile = isMobile(window.navigator).phone;

    const [archetypeIndex, setArchetypeIndex] = useState(-1);
    const [midrangeSubArchetypeIndex, setMidrangeSubArchetypeIndex] = useState(-1);
    const [controlSubArchetypeIndex, setControlSubArchetypeIndex] = useState(-1);
    const [comboSubArchetypeIndex, setComboSubArchetypeIndex] = useState(-1);
    const [subArchetypeLabel, setSubArchetypeLabel] = useState('');
    const [archetypeLabel, setArchetypeLabel] = useState('');

    // const handlePieOver = (evn) => {
    //     // console.log(JSON.stringify(evn, null, 4));
    //     setIndex(evn.payload.payload.index);
    // };

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } =
            props;

        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        let ex = mx + (cos >= 0 ? 1 : -1) * 22;
        let ey = my;

        if (mobile) {
            ey = 425;
            ex = 200;
        } else {
            ex += (cos >= 0 ? 1 : -1) * 12;
        }

        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />

                {/*<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />*/}
                {!payload.payload.hasOwnProperty('parentIndex') && (
                    <>
                        {!mobile && <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />}
                        <text x={ex} y={ey} textAnchor={textAnchor} fill="#FFF">{`${getPrettyLabel(
                            archetypeLabel
                        )} - ${value}%`}</text>
                        {subArchetypeLabel !== '' && (
                            <text x={ex} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                                {`(${getPrettyLabel(subArchetypeLabel)})`}
                            </text>
                        )}
                    </>
                )}
            </g>
        );
    };

    let _index = -1;

    for (let i = 0; i < 3; i++) {
        _index += 1;
        const key = parseOrder[i];
        const entry = deck?.details?.archetypes?.percentages[key];
        const subEntries = [];

        let _subIndex = -1;
        // if (i === 2) {
        Object.keys(entry?.subArchetypes || {}).forEach((subKey) => {
            const subEntry = entry.subArchetypes[subKey];

            const multiplier = subEntry.percentage / 100;
            const value = entry.percentage * multiplier;

            _subIndex += 1;
            subEntries.push({
                name: subKey,
                value,
                index: parseInt(_subIndex.toString()),
                parentArchetype: key,
                parentIndex: _index,
            });
        });
        // }

        const innerPadding = 5;
        const radiansUsed = 360 * (entry.percentage / 100);
        let startRadian = 0;

        startRadian = 0 - radianPosition;
        if (i > 0) {
            startRadian -= i * innerPadding;
        }

        let stopRadian = startRadian - radiansUsed;
        if (i === 1) {
            stopRadian += (i + 1) * innerPadding;
        } else if (i === 2) {
            stopRadian = -355;
        }
        archetypesChartData.push({
            name: key,
            value: entry.percentage,
            subEntries,
            startRadian: Math.ceil(startRadian),
            stopRadian: Math.ceil(stopRadian),
            index: parseInt(_index.toString()),
        });
        radianPosition += radiansUsed;
        if (i > 0) {
            radianPosition -= (i + 1) * innerPadding;
        }
    }

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const PARENT_ARCHETYPE_COLORS = {
        COMBO: '#ff4221',
        CONTROL: '#95d6ff',
        MIDRANGE: '#b1ff3d',
    };

    const handleOuterMouseOver = (evn) => {
        switch (evn.payload.payload.name) {
            case 'COMBO':
                setControlSubArchetypeIndex(-1);
                setMidrangeSubArchetypeIndex(-1);
                setComboSubArchetypeIndex(-1);
                break;
            case 'CONTROL':
                setComboSubArchetypeIndex(-1);
                setControlSubArchetypeIndex(-1);
                setMidrangeSubArchetypeIndex(-1);
                break;
            case 'MIDRANGE':
                setComboSubArchetypeIndex(-1);
                setControlSubArchetypeIndex(-1);
                setMidrangeSubArchetypeIndex(-1);
                break;
        }

        setArchetypeIndex(evn.payload.payload.index);
        setArchetypeLabel(evn.payload.payload.name);
        setSubArchetypeLabel('');
    };

    const handleInnerMouseOver = (evn) => {
        switch (evn.payload.payload.parentArchetype) {
            case 'COMBO':
                setControlSubArchetypeIndex(-1);
                setMidrangeSubArchetypeIndex(-1);
                setComboSubArchetypeIndex(evn.payload.payload.index);
                break;
            case 'CONTROL':
                setComboSubArchetypeIndex(-1);
                setControlSubArchetypeIndex(evn.payload.payload.index);
                setMidrangeSubArchetypeIndex(-1);
                break;
            case 'MIDRANGE':
                setComboSubArchetypeIndex(-1);
                setControlSubArchetypeIndex(-1);
                setMidrangeSubArchetypeIndex(evn.payload.payload.index);
                break;
        }

        setArchetypeIndex(evn.payload.payload.parentIndex);
        setArchetypeLabel(evn.payload.payload.parentArchetype);
        setSubArchetypeLabel(evn.payload.payload.name);
    };

    const getInnerIndex = (parentArchetype) => {
        switch (parentArchetype) {
            case 'COMBO':
                return comboSubArchetypeIndex;
            case 'CONTROL':
                return controlSubArchetypeIndex;
            case 'MIDRANGE':
                return midrangeSubArchetypeIndex;
        }

        return null;
    };

    const outerRadius = 160;

    const getPrettyLabel = (label) => {
        label = label.toLowerCase();
        // label = label === 'tutors' ? 'tutor' : label;
        // label = label === 'landbase' ? 'manabase' : label;
        // label = label === 'winConditions' ? 'win cons' : label;
        // label = label === 'fastmana' ? 'fast mana' : label;
        // label = label === 'combos' ? 'combo' : label;
        // label = label === 'answers' ? 'interaction' : label;
        // label = label === 'wincon' ? 'other' : label;

        label = label === 'spotremoval' ? 'spot removal' : label;
        label = label === 'cantrip' ? 'draw' : label;
        label = label === 'counters' ? 'counterspells' : label;
        label = label === 'plusoneplusonecounters' ? '+1/+1 COUNTERS' : label;
        label = label === 'extracombat' ? 'EXTRA COMBAT' : label;

        // console.log(label)
        label = label.toUpperCase();

        return label;
    };

    const getCategories = () => {
        const list = {};
        Object.keys(deck?.details?.archetypes?.archetypes)?.map((parentArchetype) => {
            switch (parentArchetype) {
                case 'MISC':
                    break;
                default:
                    let item = deck?.details?.archetypes?.archetypes?.[parentArchetype];
                    let subArchetypeList = {};
                    let items = [];
                    let label = getPrettyLabel(parentArchetype);

                    // if (item?.subCategories?.length > 0) {
                    Object.keys(item?.subArchetypes).forEach((subArchetype) => {
                        let subLabel = getPrettyLabel(subArchetype);
                        let subItem = item.subArchetypes[subArchetype];

                        subArchetypeList[subArchetype] = {
                            label: `${subLabel?.toUpperCase()}`,
                            id: subArchetype,
                            count: subItem.list.length,
                            list: subItem.list.map((_key) => {
                                return { ...cards?.[_key] };
                            }),
                            parentCategory: parentArchetype,
                        };

                        items = items.concat(
                            subItem.list.map((_key) => {
                                return {
                                    ...cards?.[_key],
                                    id: `${parentArchetype}_${_key}`,
                                };
                            }) || []
                        );
                    });

                    const itemsHash = {};
                    items.map((item) => {
                        itemsHash[item.id] = item;
                    });

                    items = Object.keys(itemsHash)?.map((key) => {
                        return itemsHash[key];
                    });

                    list[parentArchetype] = {
                        label: `${label?.toUpperCase()}`,
                        id: parentArchetype,
                        count: items?.length || 0,
                        list: items,
                        color: `#FFFFFF`,
                        subCategories: subArchetypeList,
                    };
            }
        });

        Object.keys(list).forEach((key) => {
            if (list?.[key]?.list?.length < 1) {
                delete list?.[key];
            } else {
                Object.keys(list?.[key]?.subCategories).forEach((subKey) => {
                    if (list?.[key]?.subCategories?.[subKey]?.list?.length < 1) {
                        delete list?.[key]?.subCategories?.[subKey];
                    }
                });
            }
        });

        return list;
    };

    const debug = getCategories();

    return (
        <BaseDeckPane
            title={'Archetypes'}
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getCategories()}
        >
            <Flex justifyContent={'space-around'} direction={'row'}>
                <ResponsiveContainer width="100%" height={450}>
                    <PieChart width={600} height={400}>
                        <Pie
                            data={archetypesChartData}
                            startAngle={0}
                            endAngle={-379}
                            innerRadius={outerRadius - 30}
                            outerRadius={outerRadius}
                            // maxRadius={400}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            activeIndex={archetypeIndex}
                            activeShape={renderActiveShape}
                            onMouseEnter={handleOuterMouseOver}
                            // onClick={handlePieClick}
                        >
                            {archetypesChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={PARENT_ARCHETYPE_COLORS[entry.name]} />
                            ))}
                        </Pie>
                        {archetypesChartData.map((entry, index) => (
                            // <>
                            //     {entry.subEntries.map((subEntry, subIndex) => (
                            <Pie
                                data={entry.subEntries}
                                startAngle={entry.startRadian}
                                endAngle={entry.stopRadian}
                                outerRadius={outerRadius - 50}
                                fill="#8884d8"
                                paddingAngle={0}
                                dataKey="value"
                                activeIndex={getInnerIndex(entry.name)}
                                activeShape={renderActiveShape}
                                onMouseEnter={handleInnerMouseOver}
                            >
                                {entry.subEntries.map((subEntry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            //     ))}
                            // </>))}
                            // </>
                        ))}
                    </PieChart>
                </ResponsiveContainer>
            </Flex>
        </BaseDeckPane>
    );
}
