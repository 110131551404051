import store from './redux/store/AppStore';

const calcGradePercentage = (section, useStatsRanges = false, overrideDeck) => {
    if (!store || !store?.getState()) {
        return 0;
    }

    const deck = store.getState().deck.deck;

    let ranges = {};

    // if (!) {
    ranges[REPORT_CARD_SECTIONS.SALT] = { high: 150, low: 20 };
    ranges[REPORT_CARD_SECTIONS.INTERACTION] = { high: 400, low: 20 };
    ranges[REPORT_CARD_SECTIONS.WINCONS] = { high: 300, low: 100 };
    ranges[REPORT_CARD_SECTIONS.POWERLEVEL] = { high: 10, low: 1 };
    ranges[REPORT_CARD_SECTIONS.SYNERGY] = { high: 500, low: 1 };
    // } else {
    //     ranges = store.getState().app.stats.ranges;
    // }

    let floatValue = parseFloat(deck?.score || deck?.[section]);

    // if (section === 'synergy') {
    //     floatValue = parseFloat(deck?.details?.synergy?.scoring?.score);
    // }

    if (overrideDeck) {
        floatValue = parseFloat(overrideDeck?.score || overrideDeck?.[section]);
    }
    //
    // if (section === 'synergy') {
    //     floatValue = parseFloat(overrideDeck?.details?.synergy?.scoring?.score);
    // }

    const maxValue = parseFloat(ranges?.[section].high);

    if (floatValue >= maxValue) {
        return 100;
    }

    const percentage = (floatValue / maxValue) * 100;

    return percentage;
};

const calcGradeLetter = (section, overrideDeck) => {
    const percentage = calcGradePercentage(section, false, overrideDeck);

    if (percentage < 2) {
        return REPORT_CARD_GRADE_CODES.D_MINUS;
    } else if (percentage < 5) {
        return REPORT_CARD_GRADE_CODES.D;
    } else if (percentage < 15) {
        return REPORT_CARD_GRADE_CODES.D_PLUS;
    } else if (percentage < 25) {
        return REPORT_CARD_GRADE_CODES.C_MINUS;
    } else if (percentage < 35) {
        return REPORT_CARD_GRADE_CODES.C;
    } else if (percentage < 45) {
        return REPORT_CARD_GRADE_CODES.C_PLUS;
    } else if (percentage < 55) {
        return REPORT_CARD_GRADE_CODES.B_MINUS;
    } else if (percentage < 65) {
        return REPORT_CARD_GRADE_CODES.B;
    } else if (percentage < 75) {
        return REPORT_CARD_GRADE_CODES.B_PLUS;
    } else if (percentage < 85) {
        return REPORT_CARD_GRADE_CODES.A_MINUS;
    } else if (percentage < 95) {
        return REPORT_CARD_GRADE_CODES.A;
    }

    return REPORT_CARD_GRADE_CODES.A_PLUS;
};

const calcColor = (section, overrideDeck) => {
    const percentage = calcGradePercentage(section, false, overrideDeck);
    const colorValue = Math.ceil(100 - percentage);
    // const colorValue = Math.ceil(percentage);
    return `hsl(${colorValue} 100% 50%)`;
};

export const REPORT_CARD_GRADE_CODES = {
    A_PLUS: 'A+',
    A: 'A',
    A_MINUS: 'A-',
    B_PLUS: 'B+',
    B: 'B',
    B_MINUS: 'B-',
    C_PLUS: 'C+',
    C: 'C',
    C_MINUS: 'C-',
    D_PLUS: 'D+',
    D: 'D',
    D_MINUS: 'D-',
};

export const REPORT_CARD_SECTIONS = {
    SALT: 'saltRating',
    INTERACTION: 'interactionRating',
    WINCONS: 'comboRating',
    POWERLEVEL: 'powerLevelRating',
    SYNERGY: 'synergyRating',
};

export const getGrades = (overrideDeck) => {
    return {
        salt: {
            grade: calcGradeLetter(REPORT_CARD_SECTIONS.SALT, overrideDeck),
            color: calcColor(REPORT_CARD_SECTIONS.SALT, overrideDeck),
            percentage: calcGradePercentage(REPORT_CARD_SECTIONS.SALT, false, overrideDeck),
        },
        threat: {
            grade: calcGradeLetter(REPORT_CARD_SECTIONS.INTERACTION, overrideDeck),
            color: calcColor(REPORT_CARD_SECTIONS.INTERACTION, overrideDeck),
            percentage: calcGradePercentage(REPORT_CARD_SECTIONS.INTERACTION, false, overrideDeck),
        },
        wincons: {
            grade: calcGradeLetter(REPORT_CARD_SECTIONS.WINCONS, overrideDeck),
            color: calcColor(REPORT_CARD_SECTIONS.WINCONS, overrideDeck),
            percentage: calcGradePercentage(REPORT_CARD_SECTIONS.WINCONS, false, overrideDeck),
        },
        powerlevel: {
            grade: calcGradeLetter(REPORT_CARD_SECTIONS.POWERLEVEL, overrideDeck),
            color: calcColor(REPORT_CARD_SECTIONS.POWERLEVEL, overrideDeck),
            percentage: calcGradePercentage(REPORT_CARD_SECTIONS.POWERLEVEL, false, overrideDeck),
        },
        synergy: {
            grade: calcGradeLetter(REPORT_CARD_SECTIONS.SYNERGY, overrideDeck),
            color: calcColor(REPORT_CARD_SECTIONS.SYNERGY, overrideDeck),
            percentage: calcGradePercentage(REPORT_CARD_SECTIONS.SYNERGY, false, overrideDeck),
        },
    };
};
