import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Divider, Text, ActionButton, DialogTrigger, Dialog, Heading, Content } from '@adobe/react-spectrum';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';
import './HeaderBar.css';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getId, getInstallations } from 'firebase/installations';
import isMobile from 'ismobilejs';

export function HeaderBar() {
    const dispatch = useDispatch();

    const mobile = isMobile(window.navigator).phone;
    const title = useSelector((state) => state?.app?.title);
    const route = useSelector((state) => state?.app?.route);

    const headerClassName = mobile ? 'layered-image-mobile' : 'layered-image';
    let headerCaption = '';

    switch (route.path) {
        case '':
        case '/':
            headerCaption = 'Let that sweet salt flow!';
            break;
        case '/archetypes':
            headerCaption = 'All of the different ways to annoy your friends!';
            break;
        case '/commanders':
            headerCaption = 'The saltier, the better!';
            break;
        case '/authors':
            headerCaption = 'Who is the saltiest of them all?';
            break;
        case '/contact':
            headerCaption = 'File any grievances here';
            break;
        case '/faq':
            headerCaption = 'Eh? Why dis? Who dat?';
            break;
        case '/changelog':
            headerCaption = 'Information about recent changes';
            break;
        default:
            headerCaption = '';
    }

    const handleDiscordLink = async () => {
        logEvent(getAnalytics(), 'discord_click');
        window.open(`https://discord.gg/Yb2CAxcJcf`, `_blank`);
        const installations = getInstallations(global.firebaseApp);
        const id = await getId(installations);
    };

    const handleTwitterLink = async () => {
        logEvent(getAnalytics(), 'twitter_click');
        window.open(`https://twitter.com/CMDR_SALTY_BOI`, `_blank`);
        const installations = getInstallations(global.firebaseApp);
        const id = await getId(installations);
    };

    const handlePatreonLink = async () => {
        logEvent(getAnalytics(), 'patreon_click');
        window.open(
            `https://patreon.com/user?u=4796225&utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator&utm_content=join_link`,
            `_blank`
        );
        const installations = getInstallations(global.firebaseApp);
        const id = await getId(installations);
    };

    const handleHomeClick = async () => {
        dispatch(setAppRoute({ route: '/' }));
    };

    const handleNewsClick = async () => {
        dispatch(setAppRoute({ route: '/changelog' }));
    };

    const handleMenuClick = (route, closeMethod) => {
        dispatch(setAppRoute({ route }));
        closeMethod();
    };

    return (
        <Flex width="100%" direction="column" alignItems="center">
            <Flex
                direction="row"
                width="100%"
                // margin="5px"
                justifyContent="space-between"
                height="54px"
                UNSAFE_style={{ backgroundColor: '#0e0e0e' }}
            >
                <div onClick={handleHomeClick} style={{ display: 'block', cursor: 'pointer' }}>
                    <Flex direction="row" UNSAFE_style={{ marginTop: '10px' }}>
                        <img
                            src="/resources/chef-kiss-header.png"
                            width="30px"
                            height="30px"
                            alt="CommanderSalt.com - Your new source of inspiration for degenerate MtG Commander/EDH decks!"
                            style={{ paddingLeft: '10px', paddingRight: '10px' }}
                        />
                        <Text UNSAFE_className="HeaderText">COMMANDERSALT.COM</Text>
                    </Flex>
                </div>

                <span style={{ marginRight: '5px', alignSelf: 'center', paddingRight: 5 }}>
                    <span style={{ marginRight: '5px', alignSelf: 'right' }}>
                        <ActionButton onPress={handlePatreonLink} aria-label="Support us on Patreon!" isQuiet="true">
                            <img src="/resources/patreon.png" height="20px" />
                        </ActionButton>
                    </span>
                    <span style={{ marginRight: '5px', alignSelf: 'right' }}>
                        <ActionButton onPress={handleTwitterLink} aria-label="Follow us on Twitter!" isQuiet="true">
                            <img src="/resources/twitter.png" height="25px" />
                        </ActionButton>
                    </span>
                    <span style={{ marginRight: '5px', alignSelf: 'right' }}>
                        <ActionButton onPress={handleDiscordLink} aria-label="Join our Discord!" isQuiet="true">
                            <img src="/resources/discord.png" height="25px" />
                        </ActionButton>
                    </span>
                    <DialogTrigger type="popover" mobileType="tray">
                        <ActionButton type="reset" width="size-150">
                            <ShowMenu />
                        </ActionButton>
                        {(close) => (
                            <Dialog>
                                <Heading
                                    UNSAFE_style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bolder',
                                        color: 'red',
                                    }}
                                >
                                    commandersalt
                                </Heading>
                                <Divider />
                                <Content minHeight="100vh">
                                    <Flex direction="column">
                                        <nav
                                            className="navLinks"
                                            onClick={() => {
                                                handleMenuClick(`/`, close);
                                            }}
                                        >
                                            LEADERBOARD
                                        </nav>
                                        <nav
                                            className="navLinks"
                                            onClick={() => {
                                                handleMenuClick(`/commanders`, close);
                                            }}
                                        >
                                            COMMANDERS
                                        </nav>
                                        <nav
                                            className="navLinks"
                                            onClick={() => {
                                                handleMenuClick(`/authors`, close);
                                            }}
                                        >
                                            AUTHORS
                                        </nav>
                                        {/*<nav*/}
                                        {/*    className="navLinks"*/}
                                        {/*    onClick={() => {*/}
                                        {/*        handleMenuClick(`/archetypes`, close);*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    ARCHETYPES*/}
                                        {/*</nav>*/}
                                        <Divider marginTop={10} marginBottom={10} size={'S'} />
                                        <nav
                                            className="navLinks"
                                            onClick={() => {
                                                handleMenuClick(`/meta`, close);
                                            }}
                                        >
                                            cEDH META
                                        </nav>
                                        <nav
                                            className="navLinks"
                                            onClick={() => {
                                                handleMenuClick(`/algorithm`, close);
                                            }}
                                        >
                                            THE ALGORITHM
                                        </nav>
                                        <Divider marginTop={10} marginBottom={10} size={'S'} />
                                        <nav
                                            className="navLinksSubSection"
                                            onClick={() => {
                                                handleMenuClick(`/changelog`, close);
                                            }}
                                        >
                                            - CHANGELOG
                                        </nav>
                                        <nav
                                            className="navLinksSubSection"
                                            onClick={() => {
                                                handleMenuClick(`/faq`, close);
                                            }}
                                        >
                                            - FAQ
                                        </nav>
                                        <nav
                                            className="navLinksSubSection"
                                            onClick={() => {
                                                handleMenuClick(`/contact`, close);
                                            }}
                                        >
                                            - CONTACT
                                        </nav>
                                        <nav
                                            className="navLinksSubSection"
                                            onClick={() => {
                                                handleMenuClick(`/bot`, close);
                                            }}
                                        >
                                            - DISCORD BOT
                                        </nav>
                                    </Flex>
                                </Content>
                            </Dialog>
                        )}
                    </DialogTrigger>
                </span>
            </Flex>
            <Flex direction="column" UNSAFE_className={headerClassName}>
                <div className="leaderboard-title">{title}</div>
                <div className="leaderboard-subtitle">{headerCaption}</div>
            </Flex>
        </Flex>
    );
}
