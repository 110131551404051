import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        isFetching: false,
        authors: [],
        commanders: [],
        decks: [],
    },
    reducers: {
        setValue: (state, action) => {
            if (action) {
                state[action.payload.field] = action.payload.list.map((item) => {
                    return {
                        name: item?.name || item.id,
                        id: item.id,
                    };
                });
            }

            state.isFetching = false;
        },
        setIsFetching: (state, action) => {
            state.isFetching = action;
        },
        resetSearchField: (state, action) => {
            state[action] = [];
        },
    },
});

// Actions
export const fetchList =
    (query, field, index = null) =>
    (dispatch) => {
        dispatch(setIsFetching(true));

        DynamoConnector.getShallowList(query, field, index, (list) => {
            dispatch(setValue({ field, list: list?.items || [] } || null));
        });
    };

export const { setValue, setIsFetching, resetSearchField } = searchSlice.actions;

export default searchSlice.reducer;
