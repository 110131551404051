import React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@adobe/react-spectrum';

import { BaseDeckPane } from './BaseDeckPane';

export function CombosPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const CATEGORY_CONSTANTS = {
        WINCON: {
            enum: 'WINCON',
            label: 'Wincons',
            shortLabel: 'Wincons',
            field: 'wincon',
            color: '#ff0000',
            subCategories: {},
        },
        ENABLER: {
            enum: 'ENABLER',
            label: 'Enablers',
            shortLabel: 'Enablers',
            field: 'enabler',
            color: '#faed04',
            subCategories: {
                'INFINITE-CAST': {
                    enum: 'INFINITE-CAST',
                    label: 'Cast Triggers',
                    shortLabel: 'Cast Triggers',
                    field: 'infinite-cast',
                    color: '#ff0000',
                },
                'INFINITE-STORMCOUNT': {
                    enum: 'INFINITE-STORMCOUNT',
                    label: 'Storm Triggers',
                    shortLabel: 'Storm',
                    field: 'infinite-stormcount',
                    color: '#ff0000',
                },
                'INFINITE-MANA': {
                    enum: 'INFINITE-MANA',
                    label: 'Mana',
                    shortLabel: 'Mana',
                    field: 'infinite-mana',
                    color: '#ff0000',
                },
                'INFINITE-COUNTERS': {
                    enum: 'INFINITE-COUNTERS',
                    label: 'Counters',
                    shortLabel: 'Counters',
                    field: 'infinite-counters',
                    color: '#ff0000',
                },
                'INFINITE-DRAW': {
                    enum: 'INFINITE-DRAW',
                    label: 'Draw',
                    shortLabel: 'Draw',
                    field: 'infinite-draw',
                    color: '#ff0000',
                },
                'INFINITE-SCRY': {
                    enum: 'INFINITE-SCRY',
                    label: 'Scry',
                    shortLabel: 'Scry',
                    field: 'infinite-scry',
                    color: '#ff0000',
                },
                'INFINITE-LOOT': {
                    enum: 'INFINITE-LOOT',
                    label: 'Loot',
                    shortLabel: 'Loot',
                    field: 'infinite-loot',
                    color: '#ff0000',
                },
                'INFINITE-MILL': {
                    enum: 'INFINITE-MILL',
                    label: 'Mill',
                    shortLabel: 'Mill',
                    field: 'infinite-mill',
                    color: '#ff0000',
                },
                'INFINITE-LIFE': {
                    enum: 'INFINITE-LIFE',
                    label: 'Life',
                    shortLabel: 'Life',
                    field: 'infinite-life',
                    color: '#ff0000',
                },
                'INFINITE-TOKENS': {
                    enum: 'INFINITE-TOKENS',
                    label: 'Tokens',
                    shortLabel: 'Tokens',
                    field: 'infinite-tokens',
                    color: '#ff0000',
                },
                'INFINITE-ETB': {
                    enum: 'INFINITE-ETB',
                    label: 'ETB Triggers',
                    shortLabel: 'ETB',
                    field: 'infinite-etb',
                    color: '#ff0000',
                },
                'INFINITE-LTB': {
                    enum: 'INFINITE-LTB',
                    label: 'LTB Triggers',
                    shortLabel: 'LTB',
                    field: 'infinite-ltb',
                    color: '#ff0000',
                },
                'INFINITE-UNTAP': {
                    enum: 'INFINITE-UNTAP',
                    label: 'Untap',
                    shortLabel: 'Untap',
                    field: 'infinite-untap',
                    color: '#ff0000',
                },
                'INFINITE-LANDFALL': {
                    enum: 'INFINITE-LANDFALL',
                    label: 'Landfall',
                    shortLabel: 'Landfall',
                    field: 'infinite-landfall',
                    color: '#ff0000',
                },
                'EXILE-LIBRARY': {
                    enum: 'EXILE-LIBRARY',
                    label: 'Exile Library',
                    shortLabel: 'Exile Library',
                    field: 'exile-library',
                    color: '#ff0000',
                },
                // UNCATEGORIZED: {
                //     enum: 'UNCATEGORIZED',
                //     label: 'Uncategorized',
                //     shortLabel: 'Uncategorized',
                //     field: 'uncategorized',
                //     color: '#ff0000',
                // },
            },
        },
        LOCK: {
            enum: 'LOCK',
            label: 'Locks',
            shortLabel: 'Locks',
            field: 'lock',
            color: '#F8F8FF',
            subCategories: {},
        },
    };

    const getCategories = () => {
        const list = {};

        deck?.details?.combos?.list?.map((combo) => {
            const category = combo?.type?.toUpperCase();

            const getDisplayItem = () => {
                let names = '';
                for (let i = 0; i < combo?.cards?.length; i++) {
                    if (i > 0) {
                        names = `${names}\n`;
                    }

                    names = `${names}${cards?.[combo?.cards?.[i]]?.name}`;
                }

                // let cardNames = (combo?.cards || []).map((item) => {
                //     return {
                //         ...item,
                //         ...cards?.[item?.id],
                //     };
                // });

                return {
                    id: combo.id,
                    type: 'combo',
                    cards: (combo?.cards || []).map((__card) => {
                        return cards?.[__card];
                    }),
                    // score: parseFloat(parseFloat(combo?.score)),
                    href: combo?.spellbookUri || '',
                };
            };

            if (!list?.[category]) {
                list[category] = {
                    label: `${CATEGORY_CONSTANTS?.[category]?.label?.toUpperCase()}`,
                    id: category,
                    count: 0,
                    list: [],
                    color: CATEGORY_CONSTANTS?.[category]?.color || `#FFFFFF`,
                    subCategories: {},
                };
            }

            list[category].list.push(getDisplayItem());
            list[category].count += 1;

            if (category === CATEGORY_CONSTANTS.ENABLER.enum) {
                combo?.categories?.map((subCategory) => {
                    subCategory = subCategory.toUpperCase();

                    if (CATEGORY_CONSTANTS.ENABLER.subCategories.hasOwnProperty(subCategory)) {
                        if (!list?.[category].subCategories?.[subCategory]) {
                            list[category].subCategories[subCategory] = {
                                label: `${CATEGORY_CONSTANTS.ENABLER.subCategories?.[
                                    subCategory
                                ]?.label?.toUpperCase()}`,
                                id: subCategory,
                                count: 0,
                                list: [],
                                parentCategory: category,
                                color: CATEGORY_CONSTANTS.ENABLER.subCategories?.[subCategory]?.color || `#FFFFFF`,
                            };
                        }

                        //TODO: FUUUUCK fix the backend
                        let isFound = false;
                        list[category].subCategories[subCategory].list.forEach((comboItem) => {
                            if (comboItem.id === getDisplayItem().id) {
                                isFound = true;
                            }
                        });

                        if (!isFound) {
                            list[category].subCategories[subCategory].list.push(getDisplayItem());
                            list[category].subCategories[subCategory].count += 1;
                        }
                    }
                });
            }
        });

        return {
            WINCON: list['WINCON'],
            LOCK: list['LOCK'],
            ENABLER: list['ENABLER'],
        };
    };

    return (
        <BaseDeckPane title="COMBOS" fieldName="name" showScore={false} categories={getCategories()} showLinkOut={true}>
            <Flex direction="column">
                <span>
                    These combos are scored by:
                    <ul>
                        <li>
                            How many tutors in your deck can find the pieces needed for the combo (consistency)
                            <ul>
                                <li>
                                    <b>Additional points are awarded if one of the tutors is your commander</b>
                                </li>
                            </ul>
                        </li>
                        <li>Castability of cards in the combo</li>
                        <li>Number of cards in the combo</li>
                        <li>
                            Additional points are awarded to your combo score for having multiple combos with the same
                            effect (consistency)
                        </li>
                    </ul>
                </span>
            </Flex>
        </BaseDeckPane>
    );
}
