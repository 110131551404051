import React from 'react';
import { useSelector } from 'react-redux';
import {
    ActionButton,
    Content,
    ContextualHelp,
    Divider,
    Flex,
    Grid,
    Heading,
    Item,
    Menu,
    MenuTrigger,
    repeat,
    Section,
    Text,
} from '@adobe/react-spectrum';

import { BaseDeckPane } from './BaseDeckPane';
import {
    Bar,
    BarChart,
    Legend,
    XAxis,
    YAxis,
    ResponsiveContainer,
    AreaChart,
    CartesianGrid,
    Area,
    Label,
} from 'recharts';
import styles from './manabasePane.css';
import './mana-cost.css';

export function ManabasePane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const CATEGORY_CONSTANTS = {
        ritualScores: {
            color: '#757474',
            label: 'Rituals',
            chartLabel: 'Rituals',
        },
        dorkScores: {
            color: '#36b036',
            label: 'Dorks',
            chartLabel: 'Dorks',
        },
        rockScores: {
            color: '#cebbbb',
            label: 'Rocks',
            chartLabel: 'Rocks',
        },
        landScores: {
            color: '#ad6133',
            label: 'Lands',
            chartLabel: 'Lands',
        },
        treasureScores: {
            color: '#ad6133',
            label: 'Treasure',
            chartLabel: 'Treasure',
        },
        otherScores: {
            color: '#0094ff',
            label: 'Other',
            chartLabel: 'Other',
        },
    };

    const GRAPH_CONSTANTS = {
        c: {
            color: '#757474',
            label: 'Colorless',
            chartLabel: 'Colorless',
        },
        w: {
            color: '#ffffff',
            label: 'White',
            chartLabel: 'White',
        },
        u: {
            color: '#0000FF',
            label: 'Blue',
            chartLabel: 'Blue',
        },
        b: {
            color: '#000000',
            label: 'Black',
            chartLabel: 'Black',
        },
        r: {
            color: '#FF0000',
            label: 'Red',
            chartLabel: 'Red',
        },
        g: {
            color: '#00FF00',
            label: 'Green',
            chartLabel: 'Green',
        },
    };

    const getCategories = () => {
        let list = {};

        const categoryKeys = Object.keys(CATEGORY_CONSTANTS).sort((a, b) => {
            if (CATEGORY_CONSTANTS[a].label.toLowerCase() < CATEGORY_CONSTANTS[b].label.toLowerCase()) {
                return -1;
            }
            if (CATEGORY_CONSTANTS[a].label.toLowerCase() > CATEGORY_CONSTANTS[b].label.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        categoryKeys.map((category) => {
            if (
                deck?.details?.manabase?.breakdown?.hasOwnProperty(category) &&
                Object.keys(deck?.details?.manabase?.breakdown?.[category])?.length > 0
            ) {
                const item = deck?.details?.manabase?.breakdown?.[category];
                let items = Object.keys(item).map((id) => {
                    return {
                        score: parseFloat(item?.[id] || '0.00').toFixed(2),
                        ...cards?.[id],
                    };
                });

                list[category] = {
                    label: `${CATEGORY_CONSTANTS[category]?.label}`,
                    id: category,
                    count: items?.length || 0,
                    list: items,
                    color: CATEGORY_CONSTANTS?.[category]?.color || `#FFFFFF`,
                    subCategories: [],
                    chartLabel: `${CATEGORY_CONSTANTS[category]?.chartLabel}`,
                };
            }
        });

        return list;
    };

    const data = Object.keys(deck?.details?.manabase?.breakdown?.production?.breakdown)?.map((color) => {
        const _a = Math.ceil(
            parseFloat(deck?.details?.manabase?.breakdown?.production?.breakdown?.[color]?.percentages?.requirements) *
                100
        );
        const _b = Math.ceil(
            parseFloat(
                // ?.productionToRequirementRatio
                deck?.details?.manabase?.breakdown?.production?.breakdown?.[color]?.percentages?.production
            ) * 100
        );

        return {
            name: GRAPH_CONSTANTS?.[color]?.label,
            category: GRAPH_CONSTANTS?.[color]?.label,
            Production: _b,
            Requirements: _a,
        };
    });

    const cardIds = Object.keys(deck?.details?.manabase?.probabilities?.cards || []);
    const cardIdList = () => {
        const permanentTypes = [
            'lands',
            'commanders',
            'planeswalkers',
            'creatures',
            'sorceries',
            'instants',
            'artifacts',
            'enchantments',
            'battles',
        ];

        const commanderCards = [];
        const creatures = [];
        const planeswalkers = [];
        const sorceries = [];
        const instants = [];
        const artifacts = [];
        const enchantments = [];
        const lands = [];
        const battles = [];

        deck?.commanderIds?.forEach((commanderId) => {
            if (!commanderCards.includes(commanderId)) {
                commanderCards.push(commanderId);
            }
        });

        const assignBucket = (id) => {
            let matched = false;
            permanentTypes.forEach((type) => {
                switch (type) {
                    case 'planeswalkers':
                        if (cards?.[id]?.types?.toString()?.includes('planeswalker') && !matched) {
                            planeswalkers.push(id);
                            matched = true;
                        }
                        break;
                    case 'creatures':
                        if (cards?.[id]?.types?.toString()?.includes('creature') && !matched) {
                            creatures.push(id);
                            matched = true;
                        }
                        break;
                    case 'sorceries':
                        if (cards?.[id]?.types?.toString()?.includes('sorcery') && !matched) {
                            sorceries.push(id);
                            matched = true;
                        }
                        break;
                    case 'instants':
                        if (cards?.[id]?.types?.toString()?.includes('instant') && !matched) {
                            instants.push(id);
                            matched = true;
                        }
                        break;
                    case 'artifacts':
                        if (cards?.[id]?.types?.toString()?.includes('artifact') && !matched) {
                            artifacts.push(id);
                            matched = true;
                        }
                        break;
                    case 'enchantments':
                        if (cards?.[id]?.types?.toString()?.includes('enchantment') && !matched) {
                            enchantments.push(id);
                            matched = true;
                        }
                        break;
                    case 'battles':
                        if (cards?.[id]?.types?.toString()?.includes('battle') && !matched) {
                            battles.push(id);
                            matched = true;
                        }
                        break;
                }
            });
        };

        Object.keys(deck?.cards || [])
            ?.filter((item) => {
                let retValue = true;
                deck?.commanderIds?.forEach((commanderId) => {
                    if (commanderId === item) {
                        retValue = false;
                    }
                });
                return retValue;
            })
            ?.forEach((id) => {
                assignBucket(id);
            });

        const list = [];

        if (commanderCards?.length > 0) {
            list.push({
                name: `Commander${commanderCards?.length > 1 ? 's' : ''}`,
                id: `commanders`,
                children: commanderCards
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (planeswalkers?.length > 0) {
            list.push({
                name: `Planeswalkers`,
                id: `planeswalkerCards`,
                children: planeswalkers
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (creatures?.length > 0) {
            list.push({
                name: `Creatures`,
                id: `creatureCards`,
                children: creatures
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (sorceries?.length > 0) {
            list.push({
                name: `Sorceries`,
                id: `sorceryCards`,
                children: sorceries
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (instants?.length > 0) {
            list.push({
                name: `Instants`,
                id: `instantCards`,
                children: instants
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (artifacts?.length > 0) {
            list.push({
                name: `Artifacts`,
                id: `artifactCards`,
                children: artifacts
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (enchantments?.length > 0) {
            list.push({
                name: `Enchantments`,
                id: `enchantmentCards`,
                children: enchantments
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (lands?.length > 0) {
            list.push({
                name: `Lands`,
                id: `landCards`,
                children: lands
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (battles?.length > 0) {
            list.push({
                name: `Battles`,
                id: `battleCards`,
                children: battles
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        // console.log(JSON.stringify(list, null, 4));
        return list;
    };
    // const cardData = Object.keys(deck?.details?.manabase?.probabilities?.cards || [])?.map((cardId) => {

    const cardData = [];
    const [dropDownCardId, setDropDownCardId] = React.useState(cardIdList()?.[0]?.children?.[0]?.id || null);

    if (cardIds && cardIds.length > 0) {
        for (let i = 1; i < 9; i++) {
            const card = deck.details?.manabase?.probabilities?.cards?.[dropDownCardId];
            const inHand = Math.ceil(parseFloat(card?.turns?.[i].inHand || card?.turns?.[i]) * 100);

            cardData.push({
                name: `${i}`,
                label: 'Percent Chance',
                inHand,
            });
        }
    }

    const curveData = [];

    for (let i = 1; i < 9; i++) {
        const curveObject = deck?.details?.manabase?.curveChart?.turns?.[i];
        const baseline = Math.ceil(parseFloat(curveObject?.baseLinePercentage) * 100);

        const actual = Math.ceil(parseFloat(curveObject?.actualPercentage) * 100);

        curveData.push({
            name: `${i}`,
            'Expected Percentage': baseline,
            'Actual Percentage': actual,
            outlineColor: `#8884d8`,
        });
    }

    const openingHandData = [];

    const getColorLineBySymbol = (symbol) => {
        switch (symbol) {
            case 'w':
                return `#FFFFFF`;
            case 'u':
                return `#008bff`;
            case 'b':
                return `#8647ff`;
            case 'r':
                return `#ff0000`;
            case 'g':
                return `#32ff00`;
            case 'c':
                return `#c2f5ff`;
        }

        return `#8884d8`;
    };

    const handObject = deck?.details?.manabase?.probabilities?.openingHand;

    for (let i = 0; i < 8; i++) {
        const dataObj = {
            name: `${i}`,
        };
        const colors = Object.keys(handObject?.color || {});

        colors.forEach((color) => {
            dataObj[color.toUpperCase()] = handObject.color[color][i] * 100;
        });

        dataObj['Fastmana'] = handObject?.fastmana?.[i] * 100;

        openingHandData.push(dataObj);
    }

    const handleBarClick = (data, index) => {};

    return (
        <BaseDeckPane
            title={'Manabase'}
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getCategories()}
            skipSubCategories={true}
        >
            <Flex direction="column">
                <Flex direction="row" justifyContent="center" marginTop="10px">
                    <Flex direction="column" justifyContent="center" marginEnd="10px" marginStart={10} width={'100%'}>
                        <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                            <p />
                            How well does your mana production meet your card requirements?
                        </span>

                        <Divider width="100%" marginTop="10px" size={'S'} />

                        <>
                            <Flex direction={'row'} marginTop={30}>
                                <Text UNSAFE_className={'manabaseHeaderLabel'}>
                                    Percentage for quantities in opening hand (7 cards)
                                </Text>
                                <ContextualHelp variant="help">
                                    <Heading>Pip Color Percentages</Heading>
                                    <Content>
                                        <Text>
                                            This attempts to show how likely you are to have lands (including fetch
                                            lands) that produce a given color pip in your opening hand.
                                            <p />
                                            Note that the x axis represents <b>quantity</b> cards that produce that pip.
                                            <p />
                                            Chances of drawing fast mana are also included in this graph.
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                            </Flex>
                            <Flex direction="row" justifyContent={'space-around'} marginTop={30}>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        maxWidth: '900px',
                                    }}
                                >
                                    <div>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <AreaChart
                                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                                                data={openingHandData}
                                                maxBarSize={4000}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name">
                                                    <Label
                                                        value="# of mana producing lands and fetches by color"
                                                        offset={0}
                                                        position="insideBottom"
                                                    />
                                                </XAxis>
                                                <YAxis type={'number'} domain={[0, 100]} width={25}></YAxis>
                                                <Legend />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Fastmana"
                                                    stroke="#FF00C3FF"
                                                    strokeWidth={3}
                                                    fillOpacity={0}
                                                    fill="#000000"
                                                />

                                                {Object.keys(handObject?.color || []).map((color) => {
                                                    return (
                                                        <Area
                                                            type="monotone"
                                                            dataKey={color.toUpperCase()}
                                                            stroke={getColorLineBySymbol(color)}
                                                            strokeWidth={3}
                                                            fillOpacity={0}
                                                            fill="#000000"
                                                        />
                                                    );
                                                })}
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </Flex>
                            <Flex direction={'row'} marginTop={30}>
                                <Text UNSAFE_className={'manabaseHeaderLabel'}>
                                    Percentage of cards in deck castable by turn
                                </Text>
                                <ContextualHelp variant="help">
                                    <Heading>Castability by Turn</Heading>
                                    <Content>
                                        <Text>
                                            Expected Percentage
                                            <ul>
                                                <li>
                                                    This represents what the deck would play like if all cards in it
                                                    were played on curve
                                                    <ul>
                                                        <li>
                                                            "Playing on curve" basically means playing a card on the
                                                            turn that it is first castable based on mana value. Eg. two
                                                            drops would be turn 2, three drops would be turn 3, etc
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </Text>
                                        <Text>
                                            Actual Percentage
                                            <ul>
                                                <li>
                                                    This represents number of cards per turn that are statistically able
                                                    to be cast
                                                </li>
                                            </ul>
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                            </Flex>
                            <Flex direction="row" justifyContent={'space-around'} marginTop={30}>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        maxWidth: '900px',
                                        paddingBottom: '250px',
                                        height: 200,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    >
                                        <ResponsiveContainer width="100%" height={400}>
                                            <AreaChart
                                                // layout="vertical"
                                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                                                width={400}
                                                height={400}
                                                data={curveData}
                                                // barGap={1}
                                                // tick
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name">
                                                    <Label value="Turn" offset={0} position="insideBottom" />
                                                </XAxis>
                                                <YAxis type={'number'} domain={[0, 100]} width={25} />
                                                <Legend />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Actual Percentage"
                                                    stroke="#8884d8"
                                                    strokeWidth={3}
                                                    fillOpacity={0}
                                                    fill="#000000"
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Expected Percentage"
                                                    stroke="#82ca9d"
                                                    strokeWidth={3}
                                                    fillOpacity={0}
                                                    fill="#000000"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </Flex>

                            <Flex direction={'row'} marginTop={30} marginBottom={20} columnGap={10}>
                                <Text UNSAFE_className={'manabaseMenuBoxLabel'}>Card castability:</Text>
                                <ContextualHelp variant="help">
                                    <Heading>Card Castability</Heading>
                                    <Content>
                                        <Text>
                                            Card castability refers to the statistical probability of being able to cast
                                            this card on turns 1 through 8.
                                            <p />
                                            There are a variety of things factored into this:
                                            <ul>
                                                <li>
                                                    Probability of getting mana producing lands of the appropriate pip
                                                    color
                                                    <ul>
                                                        <li>This includes fetch lands</li>
                                                    </ul>
                                                </li>
                                                <li>Quantity (density) of pips of each color</li>
                                                <li>
                                                    Total number of different pip colors required relative to turn
                                                    number
                                                </li>
                                                <li>Predicted fast mana available</li>
                                                <li>Predicted ramp available</li>
                                            </ul>
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                                <MenuTrigger>
                                    <ActionButton
                                        flexBasis={300}
                                        flexGrow={1}
                                        UNSAFE_className={'manabaseMenuBoxActionLabel'}
                                    >
                                        {deck?.cards?.[dropDownCardId]?.name}
                                    </ActionButton>
                                    <Menu
                                        items={cardIdList()}
                                        selectedKeys={dropDownCardId}
                                        onSelectionChange={(evn) => {
                                            setDropDownCardId(evn?.currentKey);
                                        }}
                                        selectionMode="single"
                                    >
                                        {(item) => (
                                            <Section items={item.children} title={item.name}>
                                                {(item) => <Item>{item.name}</Item>}
                                            </Section>
                                        )}
                                    </Menu>
                                </MenuTrigger>
                            </Flex>
                            <Flex direction="row" justifyContent={'space-around'}>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        maxWidth: '900px',
                                        paddingBottom: '250px',
                                        height: 200,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    >
                                        <ResponsiveContainer width="100%" height={400}>
                                            <AreaChart
                                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                                                width={400}
                                                height={400}
                                                data={cardData}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name">
                                                    <Label value="Turn" offset={0} position="insideBottom" />
                                                </XAxis>
                                                <YAxis type={'number'} domain={[0, 100]} width={25} />
                                                <Area
                                                    type="monotone"
                                                    dataKey="inHand"
                                                    stroke="#8884d8"
                                                    fill="#8884d8"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </Flex>
                        </>

                        <Flex direction="row" justifyContent={'space-around'}>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    maxWidth: '900px',
                                    paddingBottom: '250px',
                                    height: 200,
                                }}
                            >
                                <Text UNSAFE_className={'manabaseHeaderLabel'} marginTop={30}>
                                    Mana Production vs Requirements
                                </Text>
                                <div>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            layout="vertical"
                                            width={400}
                                            height={400}
                                            data={data}
                                            barGap={1}
                                            tick
                                        >
                                            <XAxis type="number" />
                                            <YAxis dataKey="name" type="category" width={60} tickLine={false} />
                                            <Legend />
                                            <Bar
                                                dataKey="Production"
                                                fill="#8884d8"
                                                barSize={20}
                                                onClick={handleBarClick}
                                            />
                                            <Bar
                                                dataKey="Requirements"
                                                fill="#82ca9d"
                                                barSize={10}
                                                onClick={handleBarClick}
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Flex>

                        <Flex direction="column" justifyContent="center" width={'100%'}>
                            <span className={'manabaseDetailsLabel'}>Details</span>
                            <div>
                                <span className={'manabaseDetailsSubDetailName'}>Mana producing land count:&nbsp;</span>
                                <span className={'manabaseDetailsSubDetailValue'}>
                                    {deck?.details?.manabase?.breakdown?.totalManaProducingLandCount}
                                </span>
                            </div>
                            <div>
                                <span className={'manabaseDetailsSubDetailName'}>Base total CMC:&nbsp;</span>
                                <span className={'manabaseDetailsSubDetailValue'}>
                                    {deck?.details?.manabase?.breakdown?.baseTotalCmc}
                                </span>
                            </div>
                            <div>
                                <span className={'manabaseDetailsSubDetailName'}>Generic mana reducers:&nbsp;</span>
                                <span className={'manabaseDetailsSubDetailValue'}>
                                    {Object.keys(deck?.details?.manabase?.breakdown?.genericManaRucers)?.length || 0}
                                </span>
                            </div>
                            <div>
                                <span className={'manabaseDetailsSubDetailName'}>
                                    Number of reduced cost cards:&nbsp;
                                </span>
                                <span className={'manabaseDetailsSubDetailValue'}>
                                    {deck?.details?.manabase?.details?.totalReducedCostCards}
                                </span>
                            </div>
                            <p />
                            <p />
                            <Grid columns={repeat('auto-fit', 350)} gap={30} justifyContent="center">
                                {Object.keys(deck?.details?.manabase?.breakdown?.production?.breakdown)?.map((key) => (
                                    <div id={`__breakdown_inset_${key}`} className={'manabaseStatisticsPanel'}>
                                        <div className={'manabaseStatisticsHeader'}>
                                            Statistics for&nbsp;&nbsp;
                                            <span className={`mana medium s${key}`} />
                                        </div>
                                        <Flex direction={'column'} UNSAFE_className={'manabaseStatisticsPanelContent'}>
                                            <Flex direction={'row'}>
                                                <Flex direction={'column'}>
                                                    <div className={'manabaseStatisticsSubHeader'}>Requirements:</div>
                                                    <span>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Pip quantity:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.requirements?.count
                                                            }
                                                        </span>
                                                    </span>
                                                    <span>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Average density:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.requirements?.averageDensity
                                                            }
                                                        </span>
                                                    </span>
                                                </Flex>

                                                <Flex direction={'column'}>
                                                    <div className={'manabaseStatisticsSubHeader'}>Production:</div>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Total count:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.count
                                                            }
                                                        </span>
                                                    </div>
                                                    <Flex direction={'column'}>
                                                        {Object.keys(
                                                            deck?.details?.manabase?.breakdown?.production?.breakdown?.[
                                                                key
                                                            ]?.production?.types || []
                                                        )?.map((type) => (
                                                            <div>
                                                                <span className={'manabaseStatisticsDetailName'}>
                                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                                    {type.replace(
                                                                        type.charAt(0),
                                                                        type.charAt(0).toUpperCase()
                                                                    )}
                                                                    &nbsp;
                                                                </span>
                                                                <span className={'manabaseStatisticsDetailValue'}>
                                                                    {
                                                                        deck?.details?.manabase?.breakdown?.production
                                                                            ?.breakdown[key]?.production?.types[type]
                                                                    }
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </Flex>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Average produced:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.averageProduced
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Slow producer count:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.details?.slowQuantity
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Basic land count:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.details?.basicLands
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Fetchable land count:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.details
                                                                    ?.fetchableLands
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Fetch land count:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.details?.fetchesCount
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className={'manabaseStatisticsDetailName'}>
                                                            Basic fetch land count:&nbsp;
                                                        </span>
                                                        <span className={'manabaseStatisticsDetailValue'}>
                                                            {
                                                                deck?.details?.manabase?.breakdown?.production
                                                                    ?.breakdown[key]?.production?.details
                                                                    ?.basicFetchesCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </div>
                                ))}
                            </Grid>
                            <p />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </BaseDeckPane>
    );
}
