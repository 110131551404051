import React, { useState } from 'react';
import { Flex, TextField, TextArea, ActionButton, Text } from '@adobe/react-spectrum';
import './Contact.css';
import { Helmet } from 'react-helmet';
import { sendFeedback } from '../../data/redux/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function Contact() {
    const dispatch = useDispatch();

    const isSendingEmail = useSelector((state) => state.app.isSendingEmail);
    const hasSentEmail = useSelector((state) => state.app.hasSentEmail);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleFormSubmit = () => {
        dispatch(sendFeedback({ name, email, message }));
    };

    return (
        <div>
            <BreadCrumbs />
            {!hasSentEmail ? (
                <div style={{ marginLeft: '10px', width: 'calc(100dvw - 20px)', maxWidth: '500px' }}>
                    <Flex
                        direction="column"
                        alignItems="baseline"
                        height="100%"
                        marginRight="0px"
                        marginLeft="20px"
                        gap="size-100"
                        width="100%"
                    >
                        <meta name="creator" content="Ben Rimbey" />
                        <link rel="canonical" href="https://www.commandersalt.com/contact" />
                        <meta name="description" content="Contact the Salt Maestro" />
                        <meta name="twitter:title" content="Commandersalt.com - Contact" />
                        <meta property="og:site_name" content="Commandersalt.com" />
                        <meta property="og:title" content="Commandersalt.com - Contact" />
                        <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                        <meta property="og:description" content="Contact the Salt Maestro" />
                        <h1>Questions or comments?</h1>
                        <Text>Please feel free to send me an email ;)</Text>

                        <TextField
                            label="Your name"
                            onChange={(val) => {
                                setName(val);
                            }}
                            isRequired={true}
                            isDisabled={isSendingEmail}
                            width="100%"
                        />
                        <TextField
                            label="Your Email"
                            onChange={(val) => {
                                setEmail(val);
                            }}
                            isRequired={true}
                            isDisabled={isSendingEmail}
                            width="100%"
                        />
                        <TextArea
                            label="Your questions/comments/grievances"
                            onChange={(val) => {
                                setMessage(val);
                            }}
                            isRequired={true}
                            isDisabled={isSendingEmail}
                            width="100%"
                            height="400px"
                        />
                        <ActionButton
                            type="button"
                            UNSAFE_style={{ marginTop: '20px' }}
                            onPress={handleFormSubmit}
                            isDisabled={isSendingEmail}
                        >
                            Submit
                        </ActionButton>
                    </Flex>
                    <div style={{ height: '80px' }} />
                </div>
            ) : (
                <h1>Thanks for the feedback!</h1>
            )}
        </div>
    );
}
