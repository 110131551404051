import React from 'react';
import { Flex, Text } from '@adobe/react-spectrum';
import './changelog.css';
import { Helmet } from 'react-helmet';
import { ChangelogPane } from './ChangelogPane';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function Changelog() {
    return (
        <Flex direction="column">
            <Helmet>
                <title>Changelog </title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href="https://www.commandersalt.com/faq" />
                <meta name="description" content="Changelog - Recent updates" />
                <meta name="twitter:title" content="Commandersalt.com - Changelog" />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content="Commandersalt.com - Changelog" />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content="Frequently asked questions" />
            </Helmet>
            <BreadCrumbs />
            <Flex direction="column" maxWidth={800} alignSelf={'center'} marginTop={20}>
                <ChangelogPane title="Platform Release: 07282024.4" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 08.20.2024</span>
                    <Text>
                        <span className={'subHeader'}>Card scoring updates</span>
                        <p />
                        There are updates to scoring in a lot of different card categories. Some things went higher,
                        others lower. The goal is to more accurately reflect the mechanical value of the card.
                        <p />
                        <span className={'subHeader'}>Card categories</span>
                        <p />
                        The following categories have been updated:
                        <ul>
                            <li>Tutors</li>
                            <li>Draw</li>
                            <li>Removal</li>
                            <li>Fast mana</li>
                            <li>Counterspells</li>
                            <li>Ramp</li>
                            <li>Spot Removal</li>
                            <li>Boardwipes</li>
                            <li>Mass land destruction (MLD)</li>
                            <li>Theft</li>
                            <li>Stax</li>
                            <li>Restock (Timetwister effects)</li>
                        </ul>
                        <p />
                        <span className={'subHeader'}>Manabase</span>
                        <p />
                        Last week I accidentally introduced some issues into the manabase algorithm when I was making
                        performance optimizations. This has been fixed. Also, the value of basic lands in relation to
                        manabase quality has been lowered.
                        <p />
                        <span className={'subHeader'}>Power level updates</span>
                        <p />
                        There were some issues where the synergy bonuses for cards pushed their score above the max
                        value for a given category. This has been fixed.
                        <p /> Also, the value of synergy in relation to consistency scoring has been changed for higher
                        power levels.
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07282024.3" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 08.10.2024</span>
                    <Text>
                        <span className={'subHeader'}>General maintenance</span>
                        <p />
                        The past week has been focused on trying to make some database optimizations in order to cut
                        down on expenses. This lead to some pretty wide ranging issues which should now be resolved.
                        <p />
                        Additionally, some decks were displaying crazy high combo scores. This has been fixed and now
                        the correct score is showing. This is purely a display issue; it should not effect the actual
                        deck scores.
                        <p />
                        Thanks for your patience!
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07282024.1" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 08.02.2024</span>
                    <Text>
                        <span className={'subHeader'}>Bug fixes</span>
                        <p />
                        This update primarily focused on bug fixes in the following areas:
                        <p />
                        <ul>
                            <li>Archetype detection</li>
                            <li>Card category assignment</li>
                            <li>Contextual card parsing</li>
                            <li>Synergy mappings</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07282024" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 07.28.2024</span>
                    <Text>
                        <span className={'subHeader'}>Archetypes!</span>
                        <p />
                        This is an idea that I have been wanting to add to the site since the beginning, but it wasn't
                        until now that I was able to generate enough supporting data for a deck to even begin to attempt
                        to derive the deck's archetype.
                        <p />
                        The basic idea is that the system will try to classify the deck under the classic archetypes of
                        Midrange, Control, and Combo. Aggro is basically combined with Midrange because, and this is
                        just my opinion, Aggro as an archetype more directly applies to two player formats.
                        <p />
                        <span className={'inlineHeader'}>
                            I am sure there are archetypes that are missing or misrepresented.
                        </span>
                        <p />
                        If you see such a case, PLEASE let me know on either discord or the email form on the site. I do
                        very much respect everyone's right to sh!tpost on Farcebook, Reddit, and/or Discord, but nothing
                        really changes unless I know directly ;)
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07092024.3" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 07.20.2024</span>
                    <Text>
                        <span className={'subHeader'}>Power Levels</span>
                        <p />
                        This update tries to balance out the casual scores a bit more.
                        <p />
                        As far as the scores from the site are concerned,
                        <span className={'inlineHeader'}>
                            precons are the baseline that casual decks are measured against, and precons should be under
                            a 5
                        </span>
                        .
                        <p />
                        Trying to balance this without knocking down cEDH decks and also not accidentally increasing
                        other decks scores unnecessarily (that word is unnecessarily difficult to spell, tbh lol), has
                        been a constant challenge.
                        <p />
                        With that said, the following has been updated:
                        <p />
                        <ul>
                            <li>
                                Consistency scores are weighted by the number of tutors in the deck. Let's be real - 99%
                                of the "this is a competitive list" revolve around the presence of tutors
                            </li>
                            <li>Combo score aggregation is changed slightly</li>
                            <li>Wincon scoring updated</li>
                        </ul>
                        <p />
                        Additionall, the power levels graph in the UI has been updated to use the same categories for
                        casual that are used for cEDH. The baseline values are still lower for casual, though.
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07092024.2" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 07.17.2024</span>
                    <Text>
                        <span className={'subHeader'}>Category detection fixes and improvements</span>
                        <p />
                        <ul>
                            <li>Ramp</li>
                            <li>Removal</li>
                            <li>Stax</li>
                            <li>Manafixing</li>
                            <li>Draw (added cascade and discover, as they are forms of card advantage)</li>
                            <li>Fast mana</li>
                            <li>Tutors</li>
                            <li>Theft</li>
                            <li>Graveyard</li>
                            <li>Boardwipes</li>
                            <li>Wincons</li>
                        </ul>
                        <p />
                        <span className={'subHeader'}>Synergy</span>
                        <p />
                        <ul>
                            <li>Goad - tracks creature token creation effects that affect other players</li>
                            <li>Token multiplier effects (such as Doubling Season)</li>
                            <li>Better tracking of ETB effects</li>
                            <li>General parsing improvements/fixes</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07092024.1" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 07.12.2024</span>
                    <Text>
                        <span className={'subHeader'}>Printable "Rule Zero" cards</span>
                        <p />
                        The original intent of adding power level calculations to the site was to try and facillitate
                        rule zero conversations in playgroups.
                        <p />
                        To that end, I have added a way to download a printable "rule zero card" that can be downloaded
                        from a deck's details page. The downloaded file is in PDF format, and is made to be the same
                        size/shape as a standard MtG card. For myself, I print these out and stick them in my deck boxes
                        :)
                        <p />
                        Hopefully other people find this useful too! I will be adding a way to bulk print these for all
                        of a user's decks in the near future.
                        <p />
                        Example from my own deck:
                        <p />
                        <img src={`resources/rule_zero_card_example.png`} width={400} />
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 07092024" isOpen={true}>
                    <span className={'deployDate'}>Deployed on: 07.09.2024</span>

                    <Text>
                        <span className={'subHeader'}>Wincons</span>
                        <p />
                        This update introduces the concept of "wincons" to the power level algorithm. <p />
                        The algorithm has always looked at combos that win the game, but now it is also looking at
                        ancillary conditions such as Craterhoof Behemoth in a go wide deck.
                        <p />
                        The main point of this is two fold: increase the rankings of decks that are rated lower than
                        they actually are, and also filter out decks that are incorrectly marked too highly.
                        <p />
                        This is a departure from the previous design wherein combos counted towards consistency and
                        directly/automatically affected the power level score. That system didn't scale well though, as
                        it simultaneously rewarded decks with a bunch of combos and no way to play them, and also
                        unfavorably skewed the scoring for lower power level decks.
                        <p />
                        So now, this is what we are looking at:
                        <p />
                        <ul>
                            <li>
                                <span className={'inlineHeader'}>
                                    Decks with power level less than 7 (in other words, "casual")
                                </span>
                                <p />
                                For these decks, the wincons are used as a bonus on top of the score calculated from the
                                consistency, efficiency, and interaction scores. The bonus is formulated in such a way
                                as to not push the deck's score above 7.5.
                            </li>
                            <p />
                            <li>
                                <span className={'inlineHeader'}>Decks that score above 7</span>
                                <p />
                                Decks in this range are ones that are, in my opinion, on the bleeding edge of unplayable
                                in casual pods, yet not really viable in high power to cEDH pods. That creates a
                                situation where the only real way to play them is to keep optimizing them, which leads
                                to cEDH levels of building.
                                <p />
                                With that in mind,{' '}
                                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                                    in power level 7+ decks, wincons are used as a scaling attribute instead of a bonus.
                                </span>
                                <p />
                                For example: A deck has a power level score of 9.2, because it has a lot of interaction,
                                low cmc, lots of card draw, etc, and also a solid manabase. Mechanically there is
                                nothing wrong with the deck. <b>However</b>, the deck also has no real way of closing
                                out games. There are no game ending combos, no combat damage tricks, nothing that will
                                accelerate the game towards a resolution. This tends to happen a lot of with stax decks
                                (I am super guilty of this).
                                <p />
                                So in this case, the deck has a score of 9, but a wincon score of say 50. 50 is ~16% of
                                the base value for wincons in cEDH (as far as the site is concerned). So what we do then
                                is take the amount over 7 that the deck is and multiply that by 0.16.
                                <p />
                                In other words...
                                <ul>
                                    <li>
                                        the deck in this example scores 9.2 based on consistency, efficiency,
                                        interaction, and manabase
                                    </li>
                                    <li>it has 16% of the wincons it should to be able to close out games reliably</li>
                                    <li>we subtract 7 from 9.2, which is obviously 2.2</li>
                                    <li>we multiply that by .16</li>
                                    <li>this gives us 0.352</li>
                                    <li>we then add that value to 7, yielding 7.352</li>
                                    <li>and that is the final power level score for the deck</li>
                                </ul>
                            </li>
                        </ul>
                        There will, of course, be ongoing refinements to this as we go forward; I know that not all
                        wincons are represented. One of the most glaring omissions currently is voltron. That being
                        said, please do hop in the discord and let me know if there is something that needs to be
                        updated and/or added!
                        <p />
                        <span className={'subHeader'}>Synergy</span>
                        <p />
                        All of the wincon calculations are extremely reliant on synergy correlations. In order to do
                        that, a lot of changes/fixes were made:
                        <ul>
                            <li>Better mappings of zone effects, such as leaving hand to battlefield, etc</li>
                            <li>Improved tracking of player ownership of effect</li>
                            <li>
                                Some card actions require other actions to be done in order for the effect to work. For
                                example: damage doublers. The underlying logic for this has been added, but there are
                                many dependencies not yet tracked
                            </li>
                            <li>Checks for supertypes such as legendary, snow, etc, improved</li>
                            <li>
                                Improved handling of these triggers:
                                <ul>
                                    <li>Enter the graveyard</li>
                                    <li>Die</li>
                                    <li>Draw</li>
                                    <li>Countering spells or abilities</li>
                                </ul>
                            </li>
                            <li>Many small fixes and tweaks for effect mappings</li>
                        </ul>
                        <p />
                        <span className={'subHeader'}>Manabase</span>
                        <p />
                        For manabases, I re-introduced manafixing to the scoring. It was originally part of the
                        calculations, but I removed it when I overhauled everything. Now that it's been out in the wild
                        and I've had some time to look at the results, I think that it was incorrect to remove it.
                        <p />
                        More importantly,{' '}
                        <span className={'inlineHeader'}>
                            early game acceleration is now used as a bonus for the manabase score.
                        </span>{' '}
                        I am hoping that this scales better across casual lists. cEDH lists pretty much always have high
                        scores in manabase, so this doesn't really affect them.
                        <p />
                        Additionally, I slightly increased the score deduction for etb tapped lands.
                        <p />
                        <span className={'subHeader'}>Power Levels</span>
                        <p />
                        Most of the changes here were mentioned in the wincons section, but I also updated:
                        <ul>
                            <li>Interaction now includes the 'evasion' category</li>
                            <li>Scoring for several card categories has been updated</li>
                            <li>Weighting of ramp effects in Efficiency has been lowered</li>
                        </ul>
                        <p />
                        <span className={'subHeader'}>Deck Importing</span>
                        <p />
                        Importing from Archidekt, Deckstats, and TopDecked has been improved.
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 06282024" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 06.28.2024</i>
                        </b>
                    </span>
                    This major update includes a substantial set of changes to synergy. Additionally there are fixes and
                    stabilization for manabase and card categories.
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>Better analysis and scoring of early game acceleration</li>
                        </ul>
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Categories</span>
                        <ul>
                            <li>Fixes for removal</li>
                            <li>General improvements in parsing</li>
                        </ul>
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>Better handling of "choose" effects</li>
                            <li>Support for effects that require a commander in play</li>
                            <li>Better handling of tokens</li>
                            <li>Generic fallback conversion to super type for type comparisons</li>
                            <li>Fixes for handling legendary types</li>
                            <li>Improved handling of cases where multiple types should be accepted</li>
                            <li>Better detection and comparison of zone dependencies that card effects have</li>
                            <li>Better detection and comparison of player dependencies that card effects have</li>
                            <li>Support for catching "cast" and "play" triggers from zones such as exile</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 06142024.1 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 06.15.2024</i>
                        </b>
                    </span>
                    Whoops, apparently a lot of decks got absolutely dunked on with the 06142024 update. Sorry about
                    that!
                    <p />
                    This incremental update has some slight tweaks to power level and manabase analysis to try and
                    course correct that a bit.
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 06142024" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 06.14.2024</i>
                        </b>
                    </span>
                    This update focuses on updating the power level system - the main goal is to more accurately assess
                    decks on the lower end of the scale. Hopefully this yields a net positive improvement for people.
                    <p />
                    There also various bug fixes for manabase scoring, synergy, and card categories.
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.9 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 06.13.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                Reverting 'fix' from previous release; I misidentified the issue, and this was not the
                                root cause.
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.8 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 06.13.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                Analysis of early game acceleration was horked - the calculated value was greatly
                                inflated. This lead to deck scores being higher than they should have been. This has
                                been fixed.
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.7 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 06.11.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Salt</span>
                        <ul>
                            <li>
                                The detection rules for the following salt categories have been improved:
                                <ul>
                                    <li>Board wipes</li>
                                    <li>Mass land destruction</li>
                                </ul>
                            </li>
                        </ul>
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Card categories</span>
                        <ul>
                            <li>
                                The detection rules for the following card categories have been improved:
                                <ul>
                                    <li>Counterspells</li>
                                    <li>Ramp</li>
                                    <li>Recursion</li>
                                    <li>Tutors</li>
                                </ul>
                            </li>
                        </ul>
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>
                                Correlation between token types and effects (such as Goldspan Dragon and treasures) has
                                been improved
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.6 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.30.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <p />
                        I have been making a lot of tweaks in this area the last week or so to try and balance out the
                        precons vs cedh lists, which involved a lot of fiddling with manabase acceleration and
                        individual power level metrics. Unfortunately, this has created a lot of fluctuating scores, so
                        I apologize if the results were confusing. Thanks for hanging in there with me haha.
                        <p />
                        After this update, the precons are scoring on average a bit higher than I would like, but my
                        attempts to try and keep them at lower scores have been punishing other decks a bit too harshly.
                        <p />I am going to let this sit for a bit and see how the scores look for a few weeks before
                        making more changes here, and instead focus on bug fixes.
                        <ul>
                            <li>Manabase calculations for early acceleration changed to be more realistic</li>
                            <li>
                                Power level thresholds for pretty much all categories have been adjusted to be more fair
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.6 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.29.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>
                                The method I was capping the scores with was making some decks get marginally higher
                                scores than they should have (eg .00x higher). This has been fixed.
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.5 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.28.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                Early game acceleration now looks at turns 1 and 2. Previously it looked at turns 1, 2,
                                and 3
                            </li>
                            <li>Quality assessment softened slightly</li>
                            <li>Hybrid mana analysis fixed</li>
                        </ul>
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>
                                The weightings for the different power level subcategories have been changed to try and
                                better balance precon through cedh
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.4 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.27.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                Ramp and fastmana weightings and bonuses have been tweaked to try and better represent
                                actual probability
                            </li>
                            <li>Method for determining unconditional etb tapped lands improved</li>
                            <li>
                                Negative weighting on manabase probability and quality scores improved/tweaked to be
                                more fair
                            </li>
                        </ul>
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>Changes to weighting for combos and answers</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.3 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.23.2024</i>
                        </b>
                    </span>
                    <Text>
                        This update focuses on bug fixes in categories, as well as refining the manabase scoring and
                        deck power level analysis algorithm.
                        <p />
                        As always, it is a continuous balancing act of trying to keep precons &lt;&#61; 4 without
                        lowering the mid/high/cedh decks unjustly. Hopefully your deck wasn't caught in the crossfire...
                        but if it was, my apologies. Nobody likes having their decks scored lower than what they
                        personally believe they are, but at the end of the day, some decks have to fall in the 1-3
                        range. Otherwise the whole scale is meaningless.
                        <p />
                        <i>OBLIGATORY:</i> I didn't come up with the 1-10 scale; I am just trying to implement it 🤣
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>Changed weighting for etb tapped lands for mana distribution curves</li>
                            <li>Changed application of weights for fast mana and ramp on pip calculations</li>
                            <li>Added additional support for commander ramp bonuses in distribution curves</li>
                            <li>Other bug fixes and tweaks</li>
                        </ul>
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>Manabase now has a higher max weighting on overall score</li>
                            <li>
                                <Text>
                                    <span style={{ fontWeight: 'bolder' }}>Card scoring and synergy</span>
                                    <ul>
                                        <li>
                                            Changed manner in which bonus is calculated for commander synergy within
                                            given categories
                                        </li>
                                    </ul>
                                </Text>
                            </li>
                            <li>
                                <Text>
                                    <span style={{ fontWeight: 'bolder' }}>Consistency</span>
                                    <ul>
                                        <li>Adjusted weightings for tutors, draw, and recursion</li>
                                        <li>
                                            Changed how combo scoring is aggregated; increased weighting for combo score
                                        </li>
                                    </ul>
                                </Text>
                            </li>
                            <li>
                                <Text>
                                    <span style={{ fontWeight: 'bolder' }}>Efficiency</span>
                                    <ul>
                                        <li>Changed fast mana score aggregation; increased weighting</li>
                                        <li>Increased weighting for average cmc (base value is 1.26)</li>
                                        <li>Weighting for synergy is lowered</li>
                                    </ul>
                                </Text>
                            </li>
                        </ul>
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Card Categories</span>
                        <p />
                        The following categories have been updated/improved:
                        <ul>
                            <li>Fast mana</li>
                            <li>Ramp</li>
                            <li>Recursion</li>
                            <li>Removal</li>
                            <li>Taxes</li>
                            <li>Stax</li>
                            <li>Counter spells</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.2 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.14.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                More tweaks to algorithm to account for pip density and generic mana quantity relative
                                to ramp and fast mana available on a given turn.
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024.1 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.13.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                This update has some tweaks to the manabase scoring around impact of ramp and fast mana
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 05102024" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 05.10.2024</i>
                        </b>
                    </span>
                    <Text>
                        This is a large update that revolves around redoing how the manabase analysis is scored. As with
                        all of the updates of this nature, I am sure there will be a decent amount of score changes
                        across the board. I don't like seeing peoples scores go down, but there does need to be a floor,
                        unfortunately.
                        <p />
                        Hopefully the net result will be that decks were rated too highly are now rated closer to where
                        they should be. I don't expect to see much movement with the high power and cEDH decks.
                        <p />
                        If you are interested in how the card probability is determined,{' '}
                        <u>
                            <a href={'https://en.wikipedia.org/wiki/Hypergeometric_distribution'} target={'_blank'}>
                                check this out.
                            </a>
                        </u>
                        <p />
                        With that said, we now look at three things:
                        <ul>
                            <li>
                                Acceleration
                                <ul>
                                    <li>
                                        This score revolves entirely around how quickly your deck can cast things on
                                        turns 1 through 3.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Playing on curve
                                <ul>
                                    <li>
                                        For this, we look at how much of your deck can be played on a per turn basis
                                        versus what the ideal curve would be. The{' '}
                                        <i>
                                            <b>ideal curve</b>
                                        </i>{' '}
                                        follows the traditional rule of casting 100% of your one-drops on turn 1, 100%
                                        of your two-drops on turn 2, etc.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Manabase quality
                                <ul>
                                    <li>
                                        Here we are looking at all mana sources in the deck, and evaluating based on the
                                        following
                                        <ul>
                                            <li>Does it enter tapped?</li>
                                            <li>
                                                How much mana does it generate vs what is its mana value? (in other
                                                words, net mana produced)
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.8 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.26.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>Lots of fixes for cross-dependency mapping</li>
                            <li>
                                Scoring
                                <ul>
                                    <li>Triggers for commanders are now scored much higher.</li>
                                    <li>Other synergies for commanders are also scored higher.</li>
                                    <li>Enablers are scored lower</li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.7 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.25.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>
                                Efficiency score now gets a bump from synergy if the synergy score meets certain
                                requirements.
                            </li>
                            <li>Recursion has been added as an additional factor for Consistency.</li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Card Scores and Categories</span>
                        <ul>
                            <li>Changes for calculations around castability requirements based on mana pips.</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.6 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.20.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>
                                Stax and Taxes: the overall value of these categories relative to other categories under
                                Interaction have been lowered (sorry Winota. Your time has passed 🙁)
                            </li>
                            <li>
                                Combos: Value of combos relative to other powerlevel scores has been increased (High
                                five, Teshar!).
                            </li>
                            <li>
                                Other small tweaks to level set based on feedback I have gotten from the high table.
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.5 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.15.2024</i>
                        </b>
                    </span>
                    <Text>
                        I have noticed that some decks were getting a bit too much of a bump from the recent synergy
                        bonus changes.
                        <p />
                        This update has various fixes to try and address that.
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>Interaction: the baseline threshold for this has been increased.</li>
                            <li>Synergy bonuses: bonus for synergy of all types has been decreased</li>
                        </ul>
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>Multiple fixes for parsing types for synergy context maps</li>
                            <li>
                                More nuanced lookup for synergy for specific card effects
                                <ul>
                                    <li>
                                        Removal, stax, and taxes no longer look for synergy for their respective
                                        effects. We don't really need bonuses for Trinisphere 🤣
                                        <p />
                                        <b>Note:</b> Synergy for activation costs for removal should still be counted.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.4 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.11.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power levels</span>
                        <ul>
                            <li>
                                Bonus for high synergy with deck commanders
                                <ul>
                                    <li>
                                        <Text>
                                            There are some high power builds that have few to none deterministic combos
                                            which have been being score unfairly low. This update attempts to correct
                                            that by giving a bonus based on the number of synergistic effects of cards
                                            that synergize with the commanders in the deck.
                                            <p />
                                            The idea is that this will help account for non-deterministic combos as well
                                            as increasing the value of high synergy lists.
                                        </Text>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                "Fringe cEDH"
                                <ul>
                                    <li>
                                        <Text>
                                            The definition of fringe as far as the is concerned has now changed. A lot
                                            of the decks that were classified as "fringe" were perfectly viable as cEDH,
                                            but had no recent meta representation. The vast majority of them are now
                                            allowed into the cEDH scores (capped at 9.2 though).
                                        </Text>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.3 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.11.2024</i>
                        </b>
                    </span>
                    <Text>
                        I have been doing multiple patch releases almost every evening to adjust scores and
                        categorizations, as these errors are much more visible now with the Upgradelizer function.
                        <p />
                        Oh yea, theres a new feature - the Upgradelizer! Its super beta, but the idea is to find cards
                        that might help you upgrade your deck. It doesn't show cards that are already in your deck
                        (duh). I have a ton of ideas for it. Eventually I want to recommend direct swaps for lower
                        quality cards in a decklist.
                        <p />
                        Anyways... this update is specific to manabase scoring.
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>
                                Urza, Lord High Artificer style mana production effects
                                <ul>
                                    <li>
                                        The manabase scores for these decks did not reflect the value of his ability.
                                        This should also carry over to any card with similar abilities... eg tap an
                                        untapped ____ to add ___.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.2 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.02.2024</i>
                        </b>
                    </span>
                    <Text>
                        Recent changes caused the precon power levels to get pushed up into the 5 and 6 power level
                        range. This is a problem because precons are meant to represent a pretty low bar for deck power
                        levels.
                        <p />
                        This release is focused on course correcting things that were artificially inflating their
                        scores:
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Card scores</span>
                        <ul>
                            <li>Casting cost (generic and colored pips) are now more heavily weighted</li>
                            <li>Removal - scoring now reflects target type</li>
                            <li>Draw - abilities that require sacrificing itself are now scored lower</li>
                            <li>
                                Ramp - triggered abilities that generate treasure (and gold/powerstone) tokens are now
                                scored higher
                            </li>
                            <li>
                                Ramp - harsher evaluation of cost to benefit ratio when looking at mana production
                                scoring
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <ul>
                            <li>Taplands are scored more severely</li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <ul>
                            <li>Efficiency now has a higher baseline score</li>
                        </ul>
                    </Text>
                    <Text>
                        Additionally, there were some bug fixes made to the following category detection algorithms:
                    </Text>
                    <ul>
                        <li>Graveyard</li>
                        <li>Tutors</li>
                        <li>Ramp</li>
                        <li>Removal</li>
                        <li>Fast mana</li>
                    </ul>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024.1 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 04.01.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Card categories</span>
                        <ul>
                            <li>
                                The following categories have been improved:
                                <ul>
                                    <li>Ramp</li>
                                    <li>Fast mana</li>
                                </ul>
                            </li>
                            <li>Additionally, I added "Theft" as a category (previously it was only under Salt)\</li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>Lots of fixes to relational matches of cross-dependencies between synergy maps</li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <ul>
                            <li>
                                Fixed a bug where synergy bonuses were pushing some cards above the max score per card
                            </li>
                            <li>Slightly lowered baseline scores for efficiency to match new scoring algorithm</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 03302024" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 03.30.2024</i>
                        </b>
                    </span>
                    <Text>
                        This update focuses on a complete overhaul of the card scoring system.
                        <p />
                        Additionally, we are now adding bonuses for card synergy, which is applied to deck scoring.
                        <p />
                        There is pretty much an infinite amount of things that can go wrong here, so don't be surprised
                        if you see your scores jumping around a bit over the next couple of weeks as I fine tune it :)
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 02162024.3 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 03.12.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Card categories</span>
                        <ul>
                            <li>
                                Blink
                                <ul>
                                    <li>Better aggregation of effects</li>
                                </ul>
                            </li>
                            <li>
                                Recursion
                                <ul>
                                    <li>Blink effects no longer count as recursion</li>
                                </ul>
                            </li>
                            <li>
                                Removal
                                <ul>
                                    <li>Blink effects no longer count as removal</li>
                                    <li>
                                        Effects that put -1/-1 counters on itself (such as Shield Sphere) no longer
                                        counted as removal
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Graveyard
                                <ul>
                                    <li>
                                        Effects that remove itself from a graveyard no longer count as graveyard
                                        interaction
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Draw
                                <ul>
                                    <li>Better handling of reveal effects like Atraxa, the Grand Unifier</li>
                                    <li>Better aggregation of other draw keywording</li>
                                </ul>
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Card context mapping</span>
                        <ul>
                            <li>Better parsing of trigger effects with 'for each' wording</li>
                            <li>Fix for self-reference checks on flip cards</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 02162024.3 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 03.05.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Importing</span>
                        <ul>
                            <li>
                                Deckstats
                                <ul>
                                    <li>
                                        Reverted recent change to Deckstats imports - things should be working correctly
                                        again.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 02162024.2 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 02.24.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Card categories</span>
                        <ul>
                            <li>
                                Stax
                                <ul>
                                    <li>Ward effects no longer count as stax</li>
                                    <li>Auras like Pacifism are no longer counted as stax</li>
                                </ul>
                            </li>
                            <li>
                                Draw
                                <ul>
                                    <li>
                                        Reveal to hand effects like Dark Confidant and Yuriko are now counted as draw
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Evasion
                                <ul>
                                    <li>Ward effects count as evasion</li>
                                </ul>
                            </li>
                            <li>
                                Removal
                                <ul>
                                    <li>
                                        Effects that remove/exile card(s) from graveyards no longer count as removal
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Cheat
                                <ul>
                                    <li>Better handling of Cascade effects</li>
                                </ul>
                            </li>
                            <li>
                                Ramp
                                <ul>
                                    <li>Better identification of untap effects</li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 02162024.1 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 02.19.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Manuel, Grader of Decks</span>
                        <ul>
                            <li>
                                Casual/Precon
                                <ul>
                                    <li>
                                        Decks are only marked as precon level now if they are power level 0-4 and have a
                                        combo score of zero
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Importing</span>
                        <ul>
                            <li>
                                TopDecked
                                <ul>
                                    <li>Fixed import issue for some decks</li>
                                </ul>
                            </li>
                            <li>
                                Archidekt
                                <ul>
                                    <li>Fixed issue with parsing categories for cards in decks</li>
                                    <li>Added support for importing decks from articles</li>
                                </ul>
                            </li>
                            <li>
                                MtGGoldfish
                                <ul>
                                    <li>Fixed issue with decks that had sideboards</li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 02162024" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 02.16.2024</i>
                        </b>
                    </span>
                    <Text>
                        This one was a bit of sad release.
                        <p />
                        Long story short, I have learned a lot over the course of this project, and the way that I
                        structured the data for decks on the site has changed significantly. This resulted in my having
                        to write a lot of code to transform decks with older data formats to the latest greatest format.
                        Last Thursday I deployed an update to try and update everything all at once on the site.
                        Unfortunately I received an absolutely mammoth bill from AWS from the following 24 hours, so I
                        pulled the site down until I could find a solution.
                        <p />
                        Unfortunately, the only real solution here was to delete the site's database and start over :(
                        <p />
                        There were ~139,000 decks on the site before this... We'll get back up there eventually ;)
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 01042024.2 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 01.30.2024</i>
                        </b>
                    </span>
                    <Text>
                        This update is primarily focused on backend improvements and database optimizations.
                        <p />
                        Also, we onboarded Manuel to be our resident <b>Edgelord in Chief</b>.
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Backend</span>
                        <ul>
                            <li>Fixed aggregation of decks by commander and author</li>
                            <li>Caching improvements</li>
                            <li>General bug fixes and clean up</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 01042024.1 (incremental)" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 01.11.2024</i>
                        </b>
                    </span>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Importing</span>
                        <ul>
                            <li>
                                Capped number of cards allowed in deck lists to 110. The reason its not 100 is to allow
                                people flexibility while building their lists
                                <ul>
                                    <li>Apologies to all of you with 268+ cards in your deck… 😂</li>
                                </ul>
                            </li>
                            <li>Fixed imports of colorless decks</li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <p />
                        <ul>
                            <li>* Fixed aggregation of basic fetch lands</li>
                            <li>
                                This should make people’s manafixing score and power level go up slightly if they run
                                basic fetches
                            </li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Categories</span>
                        <p />
                        <ul>
                            <li>
                                Ramp
                                <ul>
                                    <li>
                                        Cards that allow additional lands per turn, or put lands in to play are now
                                        correctly counted as ramp
                                    </li>
                                    <li>
                                        Scoring has been updated; it now looks at:
                                        <ul>
                                            <li>
                                                Play 1 (or more) additional land per turn
                                                <ul>
                                                    <li>Additional points awarded if it allows more than 1</li>
                                                </ul>
                                            </li>
                                            <li>Puts in to play tapped/untapped</li>
                                            <li>Whether or not the land searched for has to be basic</li>
                                        </ul>
                                    </li>
                                    <li>Fixed error with aggregating MDFC/transform cards</li>
                                </ul>
                            </li>
                            <li>
                                Removal
                                <ul>
                                    <li>Cards that grant Infect are no longer counted as removal</li>
                                    <li>Cards that do damage divided among targets are now counted as removal</li>
                                    <li>
                                        Added check for effects that only affect cards that aren’t of a certain type
                                    </li>
                                    <li>Added stricter scoring based on mana value</li>
                                </ul>
                            </li>
                            <li>
                                Draw
                                <ul>
                                    <li>
                                        Effects that look at the top cards of library and then puts them into your hand
                                        are now counted as card draw
                                    </li>
                                    <li>
                                        Stax effects like Spirit of the Labyrinth are no longer counted as card draw
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Stax
                                <ul>
                                    <li>
                                        Effects that restrict attacks or blocks (such as Pramikon, Sky Rampart) are now
                                        counted as stax
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Evasion
                                <ul>
                                    <li>Cards like Silence and Aurelia’s Fury are now counted as evasion</li>
                                </ul>
                            </li>
                            <li>
                                Tutor
                                <ul>
                                    <li>
                                        Fixed issue where cards with ‘search’ in their name were incorrectly counted as
                                        tutors
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <p />
                        <ul>
                            <li>Fixed trigger condition checks when there is no card type</li>
                            <ul>
                                <li>Example being Rielle’s discard trigger</li>
                            </ul>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Card Scoring</span>
                        <p />
                        <ul>
                            <li>Fixed issue where it incorrectly evaluated mana value for MDFC/transform cards</li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <p />
                        <ul>
                            <li>Casual</li>
                            <ul>
                                <li>Casual decks now get a slight bump in score based on ramp</li>
                            </ul>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 01042024" isOpen={false}>
                    <span>
                        <b>
                            <i>Deployed on: 01.04.2024</i>
                        </b>
                    </span>
                    <Text>
                        This update brings a lot of changes for manabase, card, and power level scoring. The focus on
                        all of this is to more accurately suss out a deck's power level. Many decks are going to go down
                        a bit in power level because of this update. Most of this will be due to the fact that the decks
                        in question were scored too highly in the first place.
                        <p />
                        As annoying as this is,{' '}
                        <b>I would much rather have a deck be scored lower than it objectively is than higher</b>. The
                        reason being that if the site scores your deck as an 8 when it is in reality much lower, and you
                        sit down to play in a high powered pod, you are going to get stomped... And that feels bad.
                        {/*<p />I feel that the vast majority of actual commander decks out there are far below the standards*/}
                        {/*used for cEDH decks... And that is completely fine! Everyone wants a high score, of course, but in*/}
                        {/*terms of actual playability with a group of randos, lower scores tend to be more fun. And that,*/}
                        {/*after all, is the point of not only the format. In short -{' '}*/}
                        {/*<b>do not forget to have the rule zero conversation.</b>*/}
                        {/*<p />*/}
                        {/*All that being said... The power levels generated by this site are not intended to begospel, and*/}
                        {/*they are an ever changing work in progress 😊. If you feel your scores are too low (or too high!),*/}
                        {/*please drop by the discord and let me know!*/}
                    </Text>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Card scores</span>
                        <p />
                        Card scoring is now much more granular. Scoring is derived by looking at the following list:
                        <p />
                        <ul>
                            <li>
                                <li>
                                    Casting costs
                                    <ul>
                                        <li>Card CMC</li>
                                        <li>Colored pip density</li>
                                        <li>Alternative costs</li>
                                        <li>
                                            Additional costs
                                            <ul>
                                                <li>Discard</li>
                                                <li>Sacrifice</li>
                                                <li>Exile</li>
                                                <li>Pay life</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Activation costs
                                    <ul>
                                        <li>
                                            This follows the same list as Casting Costs above, in addition to:
                                            <ul>
                                                <li>Tap/untap self</li>
                                                <li>Tap/untap other</li>
                                                <li>Sacrifice self</li>
                                                <li>Sacrifice other</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Ability type (trigger/static/etc)</li>
                            </li>
                            <li>
                                Additionally, there is a second layer of rules logic to determine points for each
                                category that is used in Power Level calculations.
                                <ul>
                                    <li>
                                        This logic varies per category, but an example would be exile vs destroy for
                                        removal cards.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Manabase</span>
                        <p />
                        The scoring for a deck's manabase has been updated/refined in the following ways:
                        <ul>
                            <li>
                                Mana Production (per card)
                                <ul>
                                    <li>Is the amount of mana easily varied (eg Gaea's Cradle)?</li>
                                    <li>Is the amount of mana variable, but not as easily?</li>
                                    <li>How much mana is produced?</li>
                                    <li>
                                        Which colors are produced?
                                        <ul>
                                            <li>
                                                This is weighted by the pip density in the deck for the colors produced
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Is it your commander?</li>
                                </ul>
                            </li>
                            <li>
                                Manabase quality (per card)
                                <ul>
                                    <li>Does it enter tapped?</li>
                                    <li>Does it enter tapped unless some condition is met (eg Cinder Glade)?</li>
                                    <li>Does it need to tap to activate?</li>
                                    <li>Is it available pregame (eg Gemstone Caverns)?</li>
                                    <li>Can you exile from hand instead of casting (eg Simian Spirit Guide)?</li>
                                    <li>Does activation require targeting something (eg Deathrite Shaman)?</li>
                                    <li>Does it have conditions for untapping (eg Basalt Monolith)?</li>
                                    <li>Is it fetchable?</li>
                                    <li>How much mana is produced relative to the cmc of the card?</li>
                                </ul>
                            </li>
                            <li>
                                Mana Fixing (in terms of entire deck)
                                <ul>
                                    <li>How many fetch lands?</li>
                                    <li>How many lands are fetchable?</li>
                                    <li>
                                        Which colors are produced?
                                        <ul>
                                            <li>
                                                This is weighted by the pip density in the deck for the colors produced
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <ul>
                            <li>
                                Combos
                                <ul>
                                    <li>
                                        The scoring for combos has evolved quite a bit. We now look at:
                                        <ul>
                                            <li>How many tutors are available for the cards in the combo</li>
                                            <li>Castability of cards in combo</li>
                                            <li>Number of cards in combo</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Baseline thresholds
                                <ul>
                                    <li>
                                        The baseline thresholds for consistency, interaction, and efficiency have been
                                        adjusted based on the output from the new card scoring system.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Power Level requirements
                                <ul>
                                    <li>
                                        Several baseline requirements have been added to gatekeep higher power levels.
                                        The thinking is that realistically 7 and 8 are decks that are on their way to
                                        being cEDH, so they need to be oriented more towards a competitive play style.
                                        <ul>
                                            <li>
                                                Power Level 7+
                                                <ul>
                                                    <li>Must include some form of combo</li>
                                                    <li>Must have a manafixing score greater than or equal to 80</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>Card Categories</span>
                        <p />
                        Detection of the following card categories has been improved:
                        <ul>
                            <li>Removal</li>
                            <li>Draw</li>
                            <li>Ramp</li>
                            <li>Recursion</li>
                            <li>Groupslug</li>
                            <li>Stax</li>
                            <li>Taxes</li>
                        </ul>
                        <div style={{ height: 30 }} />
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.7 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>cEDH Meta</span>
                        <ul>
                            <li>Meta updated</li>
                            <li>Staples list updated</li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Discord</span>
                        <p />
                        <ul>
                            <li>
                                The "/check" command now returns a summary image
                                <ul>
                                    <li>
                                        <img src={'/resources/discord_banner_preview.png'} />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.6 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Deck Importing</span>
                        <p />
                        <ul>
                            <li>
                                Archidekt
                                <ul>
                                    <li>Fixed issue wherein sideboards were included in card aggregation</li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.5 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <p />
                        <ul>
                            <li>
                                Interaction
                                <ul>
                                    <li>Taxes are now included in the calculations for interaction</li>
                                    <li>Interaction piece scores are now weighted by whether or not they are 'slow'</li>
                                </ul>
                            </li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>UI</span>
                        <ul>
                            <li>
                                Power Levels
                                <ul>
                                    <li>Now displaying Tax effects in list of cards for power level scoring</li>
                                    <li>Standardizing columns/fields in list shown between cEDH and Casual views</li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.4 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Categories</span>
                        <p />
                        <Text>Detection algorithms for the following categories have been improved:</Text>
                        <ul>
                            <li>Cheat</li>
                            <li>Counterspells</li>
                            <li>Evasion</li>
                            <li>Ramp</li>
                            <li>Removal</li>
                            <li>Stax</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.3 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Categories</span>
                        <p />
                        <ul>
                            <li>Ramp - mana rocks are once again being listed</li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Deck importing</span>
                        <p />
                        <ul>
                            <li>mtggoldfish.com - fixed import error with decks from this site</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.2 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Categories</span>
                        <p />
                        <Text>Detection algorithms for the following categories have been improved:</Text>
                        <ul>
                            <li>Draw</li>
                            <li>Evasion</li>
                            <li>Fastmana</li>
                            <li>Grouphug</li>
                            <li>Groupslug</li>
                            <li>Ramp</li>
                            <li>Removal</li>
                            <li>Stax</li>
                            <li>Tutor</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.1 (incremental)" isOpen={false}>
                    <Text>
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <ul>
                            <li>Capped 'stax weighting' bonuses</li>
                        </ul>
                    </Text>
                    <Text>
                        The stax weighting bonus is meant to bolster deck scores that are more reliant on stax than
                        combo to win. This was necessary in earlier versions of the app because combo was weighted more
                        heavily. However, the scoring algorithm appears to be more accurate now, and Winota decks were
                        getting higher scores than their meta share would dictate.
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 11022023.0" isOpen={false}>
                    <Text>
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>
                                Massive reworking of synergy lookups. The following should be more correct:
                                <ul>
                                    <li>Card type matches</li>
                                    <li>Enablers</li>
                                    <li>Triggers found</li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                    <Text>
                        It is possible that I broke some synergies that I am not aware of. If you spot something, feel
                        free to let me know in the discord!
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 10072023.1 (incremental)" isOpen={false}>
                    <Text>
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Synergy</span>
                        <ul>
                            <li>
                                Cost reduction synergy has been added
                                <ul>
                                    <li>Checks color, permanent type, etc</li>
                                    <li>Only includes cards that could actually be reduced</li>
                                    <ul>
                                        <li>eg Sapphire Medallion does not reduce Mystic Remora</li>
                                    </ul>
                                </ul>
                            </li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Manabase Analysis</span>
                        <ul>
                            <li>Added bonus and cmc adjustments for cost reduction effects</li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Card Categories</span>
                        <br />
                        <ul>
                            <li>
                                Cheat
                                <ul>
                                    <li>
                                        Rules expanded to include cascade as well as effects like Etali, Primal
                                        Conqueror, Doors of Durin, Vorinclex // The Grand Evolution
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>UI</span>
                        <ul>
                            <li>Added cost reduction information to Manabase tab.</li>
                        </ul>
                    </Text>
                </ChangelogPane>
                <ChangelogPane title="Platform Release: 10072023.0" isOpen={false}>
                    <Text>
                        This update is primarily focused on correcting decks whose Power Levels were rated higher than
                        they should be.
                        <p />
                        <span style={{ fontWeight: 'bolder' }}>Manabase Analysis</span>
                        <br />
                        This new set of rules look at the following criteria when reading a deck:
                        <ul>
                            <li>Base land quantity</li>
                            <li>Total mana production relative to the average CMC of nonland cards</li>
                            <li>
                                Ratio of colored mana production relative to pip density in cards for each color in the
                                deck
                            </li>
                            <li>Presence of fetches</li>
                            <li>Whether or not the land/rock/dork ETBs tapped</li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Card Categories</span>
                        <br />
                        The following categories have been added:
                        <ul>
                            <li>
                                Pregame
                                <ul>
                                    <li>This covers effects like Leyline of Anticipation, Gemstone Caverrns, etc</li>
                                </ul>
                            </li>
                        </ul>
                        And these have been updated and improved:
                        <ul>
                            <li>
                                Slow
                                <ul>
                                    <li>Detection of ETB Tapped and such should be better now</li>
                                </ul>
                            </li>
                            <li>
                                Cheat
                                <ul>
                                    <li>
                                        Better detection of effects that allow one to cheat cards in to play. Cards like
                                        Tooth and Nail should now be accounted for
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div style={{ height: 30 }} />
                        <span style={{ fontWeight: 'bolder' }}>Power Levels</span>
                        <br />
                        This has been tweaked in the following ways:
                        <ul>
                            <li>
                                Manabase score is now much more heavily weighted when generating a power level score
                            </li>
                            <li>
                                Decks that have less than 10 mana-producing lands are now automatically a 1.1.
                                <ul>
                                    <li>
                                        There are a lot of decks on the site currently that tried to get the highest
                                        power level and salt level possible by making unplayable decks
                                    </li>
                                    <li>
                                        The baseline amount of 10 could be too severe - I will increase it if I get
                                        feedback requesting it
                                    </li>
                                    <li>
                                        This override score supersedes any power level that would have been otherwise
                                        generated
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Decks that have a power level rating less than 5 get a boost based on their synergy
                                score
                                <ul>
                                    <li>
                                        The synergy tool is very much still a work in progress, and I am leery of
                                        rushing and having it affect all decks all at once.
                                    </li>
                                    <li>
                                        When looking at the deck scores, it seems that a lot of the decks that are under
                                        5 are more synergy focused
                                    </li>
                                    <li>
                                        Additionally, most of the cEDH lists I looked at appeared to have much lower
                                        synergy scores, which surprised me at first. However it does seem to make sense
                                        - those decks often tend to be "value piles" aimed at being as efficient as
                                        possible.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <span style={{ fontWeight: 'bolder' }}>UI</span>
                        <br />
                        The website UI has been updated:
                        <ul>
                            <li>
                                New Manabase Analysis section and score. This UI is still early in the development stage
                                and will be incrementally updated
                            </li>
                            <li>
                                Lists of individual card scores for Landbase and Ramp have been removed from the Power
                                Levels tab. Please refer to the new Manabase tab for this information
                            </li>
                        </ul>
                    </Text>
                </ChangelogPane>
            </Flex>
        </Flex>
    );
}
