import React, { Children, useState } from 'react';

import { Flex, ActionButton, Text } from '@adobe/react-spectrum';
import styles from './faqPane.css';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';

export function FaqPane(props) {
    const toggleIsOpen = (val) => {
        setIsOpen(val);
        window.dispatchEvent(new Event('resize'));
    };

    const [isOpen, setIsOpen] = useState(false);

    const buildContent = (children) => {
        return (
            <Flex direction="column" key="column" UNSAFE_style={{ marginBottom: '30px' }}>
                {Children.map(children, (child) => (
                    <div style={{ marginTop: '10px' }}>{child}</div>
                ))}
            </Flex>
        );
    };

    return (
        <Flex direction="column" width="100%">
            <Flex direction="row" alignItems="start" columnGap="40px" width="100%">
                <ActionButton
                    onPress={() => toggleIsOpen(isOpen ? false : true)}
                    width="100%"
                    // margin="10px"
                    height="25px"
                    marginBottom="5px"
                >
                    <Flex
                        direction="row"
                        width="100%"
                        UNSAFE_style={{ cursor: 'pointer' }}
                        onPress={() => toggleIsOpen(isOpen ? false : true)}
                    >
                        {isOpen ? <ChevronDown marginTop="5px" /> : <ChevronRight marginTop="5px" />}
                        <Flex direction="row" width="100%" justifyContent="space-between">
                            <Text UNSAFE_className="faqSectionsHeader">{props?.title}</Text>
                        </Flex>
                    </Flex>
                </ActionButton>
            </Flex>
            <Flex direction="column" justifyContent="space-around" isHidden={!isOpen}>
                {buildContent(props?.children)}
            </Flex>
        </Flex>
    );
}
