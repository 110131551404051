import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Cell, Flex, Text, View } from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './Commanders.css';
import {
    commandersFetchFiltered,
    fetchCommanderLeaderboardItems,
    setCommandersSortBy,
    setCommandersSortDirection,
} from '../../data/redux/slices/commandersSlice';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function Commanders() {
    const dispatch = useDispatch();
    const listItems = useSelector((state) => state?.commanders?.listItems);
    const nextCursor = useSelector((state) => state?.commanders?.nextCursor);
    const isFetching = useSelector((state) => state?.commanders?.isFetching);
    const loadingState = useSelector((state) => state?.commanders?.loadingState);
    const searchFilters = useSelector((state) => state?.commanders?.filters);
    const details = useSelector((state) => state.commanders?.details);
    const sortBy = useSelector((state) => state?.commanders?.sortBy);

    const previewIsShowing = useSelector((state) => state.preview.isShowing);

    const handleSortChange = (sortIndex) => {
        dispatch(setCommandersSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchCommanderLeaderboardItems(null, true, SORT_MAP[sortIndex], sortDirection));
    };

    const sortDirection = useSelector((state) => state.commanders.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setCommandersSortDirection(direction));
        dispatch(fetchCommanderLeaderboardItems(null, true, sortBy, direction, searchFilters));
    };

    const handleFiltersChange = (filters) => {
        dispatch(commandersFetchFiltered(filters, sortBy));
    };

    const getCellRenderer = (item, columnKey) => {
        switch (columnKey) {
            case 'score':
                const score = item.hasOwnProperty('score') ? item.score : item[sortBy.field];
                const grade = getGrades(item);
                const prop = sortBy?.enum.toLowerCase();
                let floatValue = parseFloat(/[0-9]*\.[0-9]/.exec(score.toString())?.[0] || '0');
                if (!floatValue.toString().includes(`.`)) {
                    floatValue = `${floatValue}.0`;
                }

                return (
                    <Cell>
                        <Flex direction={'column'} justifyContent={'space-around'}>
                            <div className="SaltCell" style={{ color: grade?.[prop]?.color, float: 'right' }}>
                                {floatValue}
                            </div>
                            <span className="SaltCellSubData" style={{ marginTop: 5 }}>
                                {item?.count} DECK{item?.count > 1 ? 'S' : ''}
                            </span>
                        </Flex>
                    </Cell>
                );
                break;
            case 'commanders':
                let commanderList = item?.commanders;
                if (!Array.isArray(commanderList)) {
                    commanderList = [item?.commanders];
                }

                commanderList = commanderList.map((item) => {
                    if (item.hasOwnProperty('name')) {
                        return item.name;
                    }

                    return item;
                });

                let commanders = ``;
                commanderList.forEach((commander) => {
                    if (commanders === ``) {
                        commanders = commander;
                    } else {
                        commanders = `${commanders}\n${commander}`;
                    }
                });

                return (
                    <Cell>
                        <div className="TextCell">{commanders}</div>
                    </Cell>
                );

                break;
            default:
                return (
                    <Cell>
                        <div className="TextCell">{item[columnKey]}</div>
                    </Cell>
                );
        }
    };

    const getColumnRenderer = (item) => {
        let content;

        if (item.uid === 'score') {
            content = <div />;
        } else {
            content = item.name;
        }

        return <div>{content}</div>;
    };

    const handleLeaderboardSelectionChange = (id) => {
        try {
            if (id) {
                dispatch(setAppRoute({ route: `/commanders/id/${id}` }));
            }
        } catch (error) {}
    };

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchCommanderLeaderboardItems(nextCursor, false, sortBy, sortDirection));
        }
    };

    let columns = [
        { name: 'Commander(s)', uid: 'commanders' },
        // { name: 'Count', uid: 'count', width: 125 },
        { name: '', uid: 'score', width: 90 },
    ];

    let commanderNames = '';
    let commanderNamesOneLine = '';

    for (let i = 0; i < details?.commanders?.length; i++) {
        if (i > 0) {
            commanderNames = `${commanderNames}\n`;
            commanderNamesOneLine = `${commanderNamesOneLine} + `;
        }

        commanderNames = `${commanderNames}${details?.commanders?.[i]?.name}`;
        commanderNamesOneLine = `${commanderNamesOneLine}${details?.commanders?.[i]?.name}`;
    }

    const filteredListItems = (listItems || []).filter((item) => {
        return item !== null && item.hasOwnProperty('id');
    });

    const helmetName = `Commanders`;
    const helmetCanonical = `https://www.commandersalt.com/commanders`;
    const helmetDescription = `Who is the saltiest Commander of them all?`;
    const helmetTitle = `Commandersalt.com - Who is the saltiest Commander of them all?`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <FilterPanel
                onFiltersChange={handleFiltersChange}
                filters={searchFilters}
                disableColorSelector={true}
                restrictToCommander={true}
            />
            <ResultsListing
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                disableNavChanges={true}
                allowDeckPreview={false}
                handleSelectionChange={handleLeaderboardSelectionChange}
                columns={columns}
                columnRenderer={getColumnRenderer}
                listItems={filteredListItems}
                cellRenderer={getCellRenderer}
                previewIsShowing={previewIsShowing}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
