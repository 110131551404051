import React, { Children, useEffect, useRef, useState } from 'react';
import { Content, Heading, Flex, ActionButton, IllustratedMessage, View } from '@adobe/react-spectrum';
import styles from './baseDeckPane.css';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import Alert from '@spectrum-icons/workflow/Alert';
import { Lightbox } from 'react-modal-image';

export function BaseDeckPane(props) {
    // hooks
    const [categoryViewType, setCategoryViewType] = useState(
        props?.categories?.[Object.keys(props?.categories || {})?.[0]]?.id || null
    );

    const [subCategoryViewType, setSubCategoryViewType] = useState(null);

    const [imgPreviewUri, setImgPreviewUri] = useState(null);

    const prevCategoriesList = useRef(JSON.stringify(props.categories) || '');
    useEffect(() => {
        const newCategoryString = JSON.stringify(props.categories);

        if (prevCategoriesList.current !== newCategoryString) {
            prevCategoriesList.current = newCategoryString;
            setCategoryViewType(props?.categories?.[Object.keys(props?.categories || {})?.[0]]?.id || null);
        }
    }, [props.categories]);

    const buildContent = () => {
        let items = [].concat(props?.categories ? props?.categories?.[categoryViewType]?.list || [] : props?.list);
        if (subCategoryViewType && props?.categories?.[categoryViewType]?.subCategories?.[subCategoryViewType]) {
            items = [].concat(props?.categories?.[categoryViewType]?.subCategories?.[subCategoryViewType]?.list);
        }

        // try {
        items =
            (items || [])?.sort((a, b) => {
                if (props?.showScore) {
                    return parseFloat(b.score) - parseFloat(a.score);
                } else {
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                }
            }) || [];
        // } catch (error) {

        // }

        return (
            <div>
                <div className="previewDetailSectionHeader">
                    <span>Card name(s)</span>
                    {props?.showScore && <span className="previewGridCellHeaderRight">Score</span>}
                </div>
                {items && items?.length > 0 ? (
                    items.map((item) => {
                        // console.log(`categoryViewType: ${categoryViewType}`);
                        // console.log(`subCategoryViewType: ${subCategoryViewType}`);
                        // console.log(`item.id: ${item.id}`);
                        const key = `gridCell_${Math.random()}__${categoryViewType}_${
                            subCategoryViewType ? subCategoryViewType + '_' : ''
                        }${item.id}`;

                        return (
                            <div className={'previewGridCell'} key={`GRID_PARENT_sflksdklfdsf_${key}`}>
                                <Flex
                                    direction="row"
                                    UNSAFE_className={item?.bonus && item?.bonus > 0 ? 'itemWithBonus' : ''}
                                    key={`GRID_PARENT_${key}`}
                                    justifyContent={'space-between'}
                                    columnGap={20}
                                    margin={0}
                                    wrap
                                >
                                    <div
                                        style={{
                                            display: 'initial',
                                            flex: '3 1 200px',
                                            marginBottom: '10px',
                                            width: 200,
                                            minWidth: 200,
                                            maxWidth: 500,
                                        }}
                                    >
                                        <Flex direction={'column'}>
                                            {item?.type === 'combo' ? (
                                                item.cards.map((subItem) => {
                                                    return (
                                                        <div
                                                            key={`GRID_LEFT_${key}`}
                                                            onClick={() => setImgPreviewUri(subItem?.imageUri || null)}
                                                            className={
                                                                subItem?.imageUri
                                                                    ? 'previewGridCellContentLeftClickable'
                                                                    : 'previewGridCellContentLeft'
                                                            }
                                                        >
                                                            {subItem.name}{' '}
                                                            {subItem?.count > 1 ? `(${subItem.count})` : ''}
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div
                                                    key={`GRID_LEFT_${key}`}
                                                    onClick={() => setImgPreviewUri(item?.imageUri || null)}
                                                    className={
                                                        item?.imageUri
                                                            ? 'previewGridCellContentLeftClickable'
                                                            : 'previewGridCellContentLeft'
                                                    }
                                                >
                                                    {item.name} {item?.count > 1 ? `(${item.count})` : ''}
                                                </div>
                                            )}
                                        </Flex>
                                    </div>
                                    {props?.showScore | props?.showLinkOut && (
                                        <div
                                            style={{
                                                display: 'initial',
                                                flex: '3 1 200px',
                                                marginBottom: '10px',
                                                width: 200,
                                                minWidth: 200,
                                                maxWidth: 500,
                                            }}
                                        >
                                            <Flex direction={'column'} alignItems={'end'}>
                                                <div className="previewGridCellContentRight">
                                                    {props?.showLinkOut ? (
                                                        <ActionButton
                                                            margin="0px -8px 0px 3px"
                                                            isQuiet="true"
                                                            onPress={() => window.open(item.href, `_blank`)}
                                                            key={`ACTION_${key}`}
                                                            UNSAFE_className="previewScoreBlock"
                                                        >
                                                            {item.score}
                                                            <LinkOut key={`ICON_${key}`} />
                                                        </ActionButton>
                                                    ) : (
                                                        new Number(item.score).toFixed(1)
                                                    )}
                                                </div>
                                                {item.hasOwnProperty('bonus') && item.bonus > 0.0 && (
                                                    <div className="previewGridCellContentRightBonus">
                                                        SYNERGY BONUS: {new Number(item.bonus).toFixed(1)}
                                                    </div>
                                                )}
                                            </Flex>
                                        </div>
                                    )}
                                </Flex>
                            </div>
                        );
                    })
                ) : (
                    <div style={{ height: '400px' }}>
                        <IllustratedMessage>
                            {/*<NotFound />*/}
                            <Alert size="XL" />
                            <Heading>No results</Heading>
                            <Content>No results found</Content>
                        </IllustratedMessage>
                    </div>
                )}
            </div>
        );
    };

    const getClassName = (item) => {
        if (!item?.id) {
            return '';
        }

        if (props?.skipSubCategories) {
            return !subCategoryViewType && item?.id === categoryViewType
                ? 'subCategoriesComponentLabelSelected'
                : 'subCategoriesComponentLabel';
        }

        return !subCategoryViewType && item?.id === categoryViewType
            ? 'categoriesComponentLabelSelected'
            : 'categoriesComponentLabel';
    };

    const getSubCategoryClassName = (item) => {
        let className =
            item.id === subCategoryViewType ? 'subCategoriesComponentLabelSelected' : 'subCategoriesComponentLabel';

        return className;
    };

    const handleCategoryClick = (evn) => {
        setSubCategoryViewType(null);
        setCategoryViewType(evn?.payload?.payload?.category || '');
    };

    const handleSubCategoryClick = (evn) => {
        setCategoryViewType(evn?.payload?.payload?.category || '');
        setSubCategoryViewType(evn?.payload?.payload?.subCategory || '');
    };

    const renderContentAndChildren = () => {
        return (
            <Flex direction="column" marginStart="5px" marginEnd="5px">
                <div className="previewChildSectionContainer">
                    {props.children && (
                        <Flex direction="column" key="column" UNSAFE_style={{ marginBottom: '30px' }}>
                            {Children.map(props.children, (child) => (
                                <div>{child}</div>
                            ))}
                        </Flex>
                    )}
                </div>
                {!props?.skipList && (
                    <Flex direction="row" wrap rowGap={20}>
                        {props?.categories && !props?.skipCategories && (
                            <div
                                style={{
                                    display: 'initial',
                                    flex: '0 2 50px',
                                    marginBottom: '10px',
                                    // width: '100%',
                                    minWidth: 150,
                                }}
                            >
                                <View>
                                    <div className="previewDetailSectionHeader">
                                        <span>Categories</span>
                                    </div>
                                    <div>
                                        {Object.keys(props?.categories || [])
                                            .sort((a, b) => {
                                                if (a < b) {
                                                    return -1;
                                                }
                                                if (a > b) {
                                                    return 1;
                                                }
                                            })
                                            .map((key) => {
                                                const item = props?.categories[key];

                                                if (!item) {
                                                    return <View key={key} marginEnd="10px" />;
                                                }

                                                return (
                                                    <View key={key} marginEnd="10px">
                                                        <Flex
                                                            direction="row"
                                                            UNSAFE_style={{
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    marginRight: '5px',
                                                                    marginTop: '15px',
                                                                    display: 'block',
                                                                    backgroundColor: item?.color,
                                                                }}
                                                            />
                                                            <span
                                                                className={getClassName(item)}
                                                                onClick={() => {
                                                                    handleCategoryClick({
                                                                        payload: {
                                                                            payload: { category: item?.id },
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                {item?.label} ({item?.count})
                                                            </span>
                                                        </Flex>
                                                        {Object.keys(item?.subCategories || []).length > 0 && (
                                                            <Flex direction="column">
                                                                <div>
                                                                    {Object.keys(item?.subCategories || [])
                                                                        .sort((a, b) => {
                                                                            if (a < b) {
                                                                                return -1;
                                                                            }
                                                                            if (a > b) {
                                                                                return 1;
                                                                            }
                                                                        })
                                                                        .map((subKey) => {
                                                                            const subItem = item?.subCategories[subKey];

                                                                            return (
                                                                                <View key={subKey}>
                                                                                    <Flex
                                                                                        direction="row"
                                                                                        UNSAFE_style={{
                                                                                            whiteSpace: 'nowrap',
                                                                                            fontWeight: 'bold',
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                width: '10px',
                                                                                                height: '10px',
                                                                                                marginRight: '5px',
                                                                                                marginTop: '15px',
                                                                                                display: 'block',
                                                                                                // backgroundColor: subComboItem.color,
                                                                                            }}
                                                                                        />
                                                                                        <span
                                                                                            className={getSubCategoryClassName(
                                                                                                subItem
                                                                                            )}
                                                                                            onClick={() => {
                                                                                                handleSubCategoryClick({
                                                                                                    payload: {
                                                                                                        payload: {
                                                                                                            subCategory:
                                                                                                                subItem?.id,
                                                                                                            category:
                                                                                                                subItem?.parentCategory,
                                                                                                        },
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            └&nbsp;&nbsp;
                                                                                            {subItem?.label?.toUpperCase()}
                                                                                        </span>
                                                                                    </Flex>
                                                                                </View>
                                                                            );
                                                                        })}
                                                                </div>
                                                            </Flex>
                                                        )}
                                                    </View>
                                                );
                                            })}
                                    </div>
                                </View>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'initial',
                                flex: '2 0 100px',
                                marginBottom: '10px',
                                width: 400,
                                minWidth: 100,
                            }}
                        >
                            <div style={{ width: '100%' }} className="previewGridContainer">
                                {buildContent()}
                            </div>
                        </div>
                    </Flex>
                )}
            </Flex>
        );
    };

    return (
        <Flex direction="column" justifyContent="space-around">
            {imgPreviewUri && (
                <div onClick={() => setImgPreviewUri(null)}>
                    <Lightbox
                        medium={''}
                        large={imgPreviewUri}
                        hideDownload={true}
                        hideZoom={true}
                        onClose={() => setImgPreviewUri(null)}
                    />
                </div>
            )}
            <div className="previewExtraContentContainer">{props?.content || <div />}</div>
            {renderContentAndChildren()}
        </Flex>
    );
}
