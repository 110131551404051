import React, { Children, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ComboBox,
    Divider,
    Flex,
    Item,
    Section,
    Text,
    MenuTrigger,
    ActionButton,
    Menu,
    IllustratedMessage,
    Heading,
    Content,
    Tabs,
    TabPanels,
    TabList,
} from '@adobe/react-spectrum';
import styles from './SynergyPane.css';

import { BaseDeckPane } from './BaseDeckPane';
import { handleUpdatedAppRoute } from '../../../data/redux/slices/appSlice';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import Alert from '@spectrum-icons/workflow/Alert';
import { Lightbox } from 'react-modal-image';
import './mana-cost.css';

export function SynergyPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const CATEGORY_CONSTANTS = {
        annihilator: {
            color: '#d0d0d0',
            label: 'Annihilator',
            chartLabel: 'Annihilator',
        },
        boardwipes: {
            color: '#ffd47a',
            label: 'Board Wipes',
            chartLabel: 'Board Wipes',
        },
        cant: {
            color: '#ffffff',
            label: `You can't...`,
            chartLabel: `Uh... you can't do that`,
        },
        mld: {
            color: '#ff0000',
            label: 'MLD',
            chartLabel: 'Mass Land Destruction',
        },
        taxes: {
            color: '#6969c0',
            label: 'Taxes',
            chartLabel: 'Tax effects',
        },
        payTheOne: {
            color: '#ffdd00',
            label: 'Pay the...?',
            chartLabel: 'Do you pay the...?',
        },
        powergamer: {
            color: '#dd00ff',
            label: 'cEDH Staples',
            chartLabel: 'cEDH Staples',
            noChart: true,
        },
        sacrifice: {
            color: '#1a0000',
            label: 'Sacrifice',
            chartLabel: 'Sacrifice',
        },
        infiniteCombos: {
            color: '#00baff',
            label: 'Infinte Combos',
            chartLabel: 'Infinite combos',
        },
        reserved: {
            color: '#4d1600',
            label: 'Reserved List',
            chartLabel: 'Reserved List cards',
            noChart: true,
        },
        edhrec: {
            color: '#00ffa4',
            label: 'EDHRec Scores',
            chartLabel: 'EDHRec Scores',
            noChart: true,
        },
        locks: {
            color: '#0033ff',
            label: 'Locks',
            chartLabel: 'Locks',
        },
        theft: {
            color: '#6600ff',
            label: 'Theft',
            chartLabel: 'Theft',
        },
        chaos: {
            color: '#da0083',
            label: 'Chaos',
            chartLabel: 'Chaos effects',
        },
        groupSlug: {
            color: '#96000d',
            label: 'Group Slug',
            chartLabel: 'Group Slug',
        },
        extraTurns: {
            color: '#00e0ff',
            label: 'Extra Turns',
            chartLabel: 'Extra Turns',
        },
        extraCombats: {
            color: '#ffc500',
            label: 'Extra Combats',
            chartLabel: 'Extra Combats',
        },
        infect: {
            color: '#1c4b83',
            label: 'Infect',
            chartLabel: 'Infect',
        },
        wheels: {
            color: '#ff5409',
            label: 'Wheels',
            chartLabel: 'Wheels',
        },
    };

    const SUBCATEGORY_CONSTANTS = {
        cant: {
            cantActivate: {
                label: `Activate`,
                chartLabel: `Can't Activate`,
            },
            cantAttack: {
                label: `Attack`,
                chartLabel: `Can't Attack`,
            },
            cantCast: {
                label: `Cast`,
                chartLabel: `Can't Cast`,
            },
            cantDraw: {
                label: `Draw`,
                chartLabel: `Can't Draw`,
            },
            cantEven: {
                label: `Even`,
                chartLabel: `Can't Even`,
            },
            cantGainLife: {
                label: `Gain Life`,
                chartLabel: `Can't Gain Life`,
            },
            cantSearch: {
                label: `Search`,
                chartLabel: `Can't Search`,
            },
            cantTrigger: {
                label: `Trigger`,
                chartLabel: `Can't Trigger`,
            },
            cantUntap: {
                label: `Untap`,
                chartLabel: `Can't Untap`,
            },
        },
    };

    const cardIdList = () => {
        const permanentTypes = [
            'lands',
            'commanders',
            'planeswalkers',
            'creatures',
            'sorceries',
            'instants',
            'artifacts',
            'enchantments',
            'battles',
        ];

        const commanderCards = [];
        const creatures = [];
        const planeswalkers = [];
        const sorceries = [];
        const instants = [];
        const artifacts = [];
        const enchantments = [];
        const lands = [];
        const battles = [];

        const assignBucket = (id) => {
            let matched = false;
            permanentTypes.forEach((type) => {
                switch (type) {
                    case 'commanders':
                        if (deck?.commanderIds?.toString()?.includes(id) && !matched) {
                            commanderCards.push(id);
                            matched = true;
                        }
                        break;
                    case 'planeswalkers':
                        if (cards?.[id]?.types?.toString()?.includes('planeswalker') && !matched) {
                            planeswalkers.push(id);
                            matched = true;
                        }
                        break;
                    case 'creatures':
                        if (cards?.[id]?.types?.toString()?.includes('creature') && !matched) {
                            creatures.push(id);
                            matched = true;
                        }
                        break;
                    case 'sorceries':
                        if (cards?.[id]?.types?.toString()?.includes('sorcery') && !matched) {
                            sorceries.push(id);
                            matched = true;
                        }
                        break;
                    case 'instants':
                        if (cards?.[id]?.types?.toString()?.includes('instant') && !matched) {
                            instants.push(id);
                            matched = true;
                        }
                        break;
                    case 'artifacts':
                        if (cards?.[id]?.types?.toString()?.includes('artifact') && !matched) {
                            artifacts.push(id);
                            matched = true;
                        }
                        break;
                    case 'enchantments':
                        if (cards?.[id]?.types?.toString()?.includes('enchantment') && !matched) {
                            enchantments.push(id);
                            matched = true;
                        }
                        break;
                    case 'lands':
                        if (cards?.[id]?.types?.toString()?.includes('land') && !matched) {
                            lands.push(id);
                            matched = true;
                        }
                        break;
                    case 'battles':
                        if (cards?.[id]?.types?.toString()?.includes('battle') && !matched) {
                            battles.push(id);
                            matched = true;
                        }
                        break;
                }
            });
        };

        Object.keys(deck?.details?.synergy?.list || [])?.forEach((id) => {
            assignBucket(id);
        });

        const list = [];

        if (commanderCards?.length > 0) {
            list.push({
                name: `Commander${commanderCards?.length > 1 ? 's' : ''}`,
                id: `commanders`,
                children: commanderCards
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (planeswalkers?.length > 0) {
            list.push({
                name: `Planeswalkers`,
                id: `planeswalkerCards`,
                children: planeswalkers
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (creatures?.length > 0) {
            list.push({
                name: `Creatures`,
                id: `creatureCards`,
                children: creatures
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (sorceries?.length > 0) {
            list.push({
                name: `Sorceries`,
                id: `sorceryCards`,
                children: sorceries
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (instants?.length > 0) {
            list.push({
                name: `Instants`,
                id: `instantCards`,
                children: instants
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (artifacts?.length > 0) {
            list.push({
                name: `Artifacts`,
                id: `artifactCards`,
                children: artifacts
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (enchantments?.length > 0) {
            list.push({
                name: `Enchantments`,
                id: `enchantmentCards`,
                children: enchantments
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (lands?.length > 0) {
            list.push({
                name: `Lands`,
                id: `landCards`,
                children: lands
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        if (battles?.length > 0) {
            list.push({
                name: `Battles`,
                id: `battleCards`,
                children: battles
                    .map((id) => {
                        return {
                            id,
                            name: cards?.[id]?.name,
                        };
                    })
                    ?.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                    }),
            });
        }

        // console.log(JSON.stringify(list, null, 4));
        return list;
    };

    const effectIdToTextMapping = {};

    const prettyFormatEffectName = (id = effectId) => {
        const blob = effectIdToTextMapping[id];
        let text = blob?.text || '';

        text = text?.charAt(0)?.toUpperCase() + text?.slice(1) || '';

        return text;
    };

    const getPrettyFormattedEffectLabel = (id = effectId) => {
        const blob = effectIdToTextMapping[id];
        let text = blob.text;
        let preText = '';
        let postText = '';

        const replacementRegExp = new RegExp(`${text}`, 'i');
        const replacementText = blob.contextIds.lineText.match(replacementRegExp)?.[0] || '';
        if (replacementText !== text) {
            preText = text.substring(0, text.indexOf(replacementText));
        }

        // <div className={'synergyMenuBoxItem'}>
        //     <span style={{color: '#FF0000'}}>{text}</span>
        // </div>

        return (
            <Item>
                <span className={'synergyMenuBoxItem'}>{text}</span>
            </Item>
        );
    };

    const effectIdList = () => {
        const triggers = Object.keys(deck?.details?.synergy?.list?.[cardId]?.triggers || []);
        const abilities = Object.keys(deck?.details?.synergy?.list?.[cardId]?.abilities || []);
        const statics = Object.keys(deck?.details?.synergy?.list?.[cardId]?.statics || []);
        const textParts = Object.keys(deck?.details?.synergy?.list?.[cardId]?.textParts || []);
        const manaCost = Object.keys(deck?.details?.synergy?.list?.[cardId]?.manaCost || []);
        // const replacementEffects = Object.keys(deck?.details?.synergy?.list?.[cardId]?.replacement || []);

        const retList = [];

        if (manaCost.length > 0) {
            retList.push({
                name: `Cast`,
                id: `manaCost`,
                children:
                    Object.keys(deck?.details?.synergy?.list?.[cardId]?.manaCost || [])
                        // ?.sort((a, b) => {
                        //     if (a < b) {
                        //         return -1;
                        //     }
                        //     if (a > b) {
                        //         return 1;
                        //     }
                        // })
                        ?.map((id) => {
                            effectIdToTextMapping[id] = deck?.details?.synergy?.list?.[cardId]?.manaCost[id];

                            return {
                                id: id,
                                name: prettyFormatEffectName(id),
                            };
                        }) || [],
            });
        }

        if (textParts.length > 0) {
            retList.push({
                name: `Card Text`,
                id: `textParts`,
                children:
                    Object.keys(deck?.details?.synergy?.list?.[cardId]?.textParts || [])
                        // ?.sort((a, b) => {
                        //     if (a < b) {
                        //         return -1;
                        //     }
                        //     if (a > b) {
                        //         return 1;
                        //     }
                        // })
                        ?.map((id) => {
                            effectIdToTextMapping[id] = deck?.details?.synergy?.list?.[cardId]?.textParts[id];
                            return {
                                id: id,
                                name: prettyFormatEffectName(id),
                            };
                        }) || [],
            });
        }

        if (triggers?.length > 0) {
            retList.push({
                name: `Triggered Abilities`,
                id: `triggeredAbilities`,
                children:
                    Object.keys(deck?.details?.synergy?.list?.[cardId]?.triggers || [])
                        // ?.sort((a, b) => {
                        //     if (a < b) {
                        //         return -1;
                        //     }
                        //     if (a > b) {
                        //         return 1;
                        //     }
                        // })
                        ?.map((id) => {
                            effectIdToTextMapping[id] = deck?.details?.synergy?.list?.[cardId]?.triggers[id];

                            return {
                                id: id,
                                name: prettyFormatEffectName(id),
                            };
                        }) || [],
            });
        }

        if (abilities?.length > 0) {
            retList.push({
                name: `Activated Abilities`,
                id: `activatedAbilities`,
                children:
                    Object.keys(deck?.details?.synergy?.list?.[cardId]?.abilities || [])
                        // ?.sort((a, b) => {
                        //     if (a < b) {
                        //         return -1;
                        //     }
                        //     if (a > b) {
                        //         return 1;
                        //     }
                        // })
                        ?.map((id) => {
                            effectIdToTextMapping[id] = deck?.details?.synergy?.list?.[cardId]?.abilities[id];

                            return {
                                id: id,
                                name: prettyFormatEffectName(id),
                            };
                        }) || [],
            });
        }

        if (statics?.length > 0) {
            retList.push({
                name: `Static Abilities`,
                id: `staticAbilities`,
                children:
                    Object.keys(deck?.details?.synergy?.list?.[cardId]?.statics || [])
                        // ?.sort((a, b) => {
                        //     if (a < b) {
                        //         return -1;
                        //     }
                        //     if (a > b) {
                        //         return 1;
                        //     }
                        // })
                        ?.map((id) => {
                            effectIdToTextMapping[id] = deck?.details?.synergy?.list?.[cardId]?.statics[id];

                            return {
                                id: id,
                                name: prettyFormatEffectName(id),
                            };
                        }) || [],
            });
        }

        return retList;
    };

    // console.log(JSON.stringify(effectIdToTextMapping, null, 4));

    let [cardId, setCardId] = React.useState(cardIdList()?.[0]?.children?.[0]?.id || null);
    let [effectId, setEffectId] = React.useState(effectIdList()?.[0]?.children?.[0]?.id || null);

    const getImageUri = () => {
        return cards?.[cardId]?.imageUri || '';
    };

    const getListViewData = () => {
        let list = {};

        const byTrigger = deck?.details?.synergy?.list?.[cardId]?.triggers?.[effectId]?.sources;
        const byCombo = deck?.details?.synergy?.list?.[cardId]?.triggers?.[effectId]?.combos;
        const byType = deck?.details?.synergy?.list?.[cardId]?.triggers?.[effectId]?.cardsOfSupportingType;
        const byColor = deck?.details?.synergy?.list?.[cardId]?.triggers?.[effectId]?.cardsOfSupportingColor;
        const byTarget = deck?.details?.synergy?.list?.[cardId]?.abilities?.[effectId]?.targets;
        const byStaticType = deck?.details?.synergy?.list?.[cardId]?.statics?.[effectId]?.cardsOfSupportingType;
        const byTextPartCardsOfSupportingType =
            deck?.details?.synergy?.list?.[cardId]?.textParts?.[effectId]?.textPartCardsOfSupportingType;
        const byComboForManaCost = deck?.details?.synergy?.list?.[cardId]?.manaCost?.[effectId]?.combos;
        const byComboForAbility = deck?.details?.synergy?.list?.[cardId]?.abilities?.[effectId]?.combos;

        const byEnablersForTriggers = deck?.details?.synergy?.list?.[cardId]?.triggers?.[effectId]?.enablers;
        const byEnablersForAbilities = deck?.details?.synergy?.list?.[cardId]?.abilities?.[effectId]?.enablers;
        const byEnablersForStatics = deck?.details?.synergy?.list?.[cardId]?.statics?.[effectId]?.enablers;
        const byEnablersForTextPart = deck?.details?.synergy?.list?.[cardId]?.textParts?.[effectId]?.enablers;

        const byTypeForAbility = deck?.details?.synergy?.list?.[cardId]?.abilities?.[effectId]?.cardsOfSupportingType;

        if (byTypeForAbility?.length > 0) {
            list['typeForAbility'] = {
                label: 'Card Type',
                id: 'typeForAbility',
                count: byTypeForAbility?.length || 0,
                list: (byTypeForAbility || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'typeForAbility',
            };
        }

        if (byEnablersForTriggers?.length > 0) {
            list['enablersForTriggers'] = {
                label: 'Enablers',
                id: 'enablersForTriggers',
                count: byEnablersForTriggers?.length || 0,
                list: (byEnablersForTriggers || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'enablersForTriggers',
            };
        }

        if (byEnablersForAbilities?.length > 0) {
            list['enablersForAbilities'] = {
                label: 'Enablers',
                id: 'enablersForAbilities',
                count: byEnablersForAbilities?.length || 0,
                list: (byEnablersForAbilities || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'enablersForAbilities',
            };
        }

        if (byEnablersForStatics?.length > 0) {
            list['enablersForStatics'] = {
                label: 'Enablers',
                id: 'enablersForStatics',
                count: byEnablersForStatics?.length || 0,
                list: (byEnablersForStatics || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'enablersForStatics',
            };
        }

        if (byEnablersForTextPart?.length > 0) {
            list['enablersForTextPart'] = {
                label: 'Enablers',
                id: 'enablersForTextPart',
                count: byEnablersForTextPart?.length || 0,
                list: (byEnablersForTextPart || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'enablersForTextPart',
            };
        }

        if (byComboForManaCost?.length > 0) {
            list['comboManaCost'] = {
                label: 'Combos',
                id: 'comboManaCost',
                count: byComboForManaCost?.length || 0,
                list: (byComboForManaCost || [])?.map((combo) => {
                    return {
                        ...combo,
                        name: combo?.name?.toString()?.replace(/,(?! )/g, `\n`),
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'comboManaCost',
            };
        }

        if (byComboForAbility?.length > 0) {
            list['comboForAbility'] = {
                label: 'Combos',
                id: 'comboForAbility',
                count: byComboForAbility?.length || 0,
                list: (byComboForAbility || [])?.map((combo) => {
                    return {
                        ...combo,
                        name: combo?.name?.toString()?.replace(/,(?! )/g, `\n`),
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'comboForAbility',
            };
        }

        if (byTextPartCardsOfSupportingType?.length > 0) {
            list['textParts'] = {
                label: 'Card Type',
                id: 'textParts',
                count: byTextPartCardsOfSupportingType?.length || 0,
                list: (byTextPartCardsOfSupportingType || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'textParts',
            };
        }

        if (byTrigger?.length > 0) {
            list['triggers'] = {
                label: 'Triggers',
                id: 'triggers',
                count: byTrigger?.length || 0,
                list: (byTrigger || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'triggers',
            };
        }

        if (byCombo?.length > 0) {
            list['combos'] = {
                label: 'Combos',
                id: 'combos',
                count: byCombo?.length || 0,
                list: (byCombo || [])?.map((combo) => {
                    return {
                        ...combo,
                        name: combo?.name?.toString()?.replace(/,(?! )/g, `\n`),
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'combos',
            };
        }

        if (byType?.length > 0) {
            list['type'] = {
                label: 'Card Type',
                id: 'type',
                count: byType?.length || 0,
                list: (byType || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'types',
            };
        }

        if (byStaticType?.length > 0) {
            list['staticType'] = {
                label: 'Card Type',
                id: 'staticType',
                count: byStaticType?.length || 0,
                list: (byStaticType || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'types',
            };
        }

        if (byColor?.length > 0) {
            list['color'] = {
                label: 'Card Color',
                id: 'color',
                count: byColor?.length || 0,
                list: (byColor || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'colors',
            };
        }

        if (byTarget?.length > 0) {
            list['targets'] = {
                label: 'Targets',
                id: 'targets',
                count: byTarget?.length || 0,
                list: (byTarget || [])?.map((id) => {
                    return {
                        ...cards?.[id],
                    };
                }),
                color: `#FFFFFF`,
                subCategories: {},
                chartLabel: 'targets',
            };
        }

        return list;
    };

    const prevCardId = useRef(cardId || '');

    // console.log(JSON.stringify(getListViewData()?.triggerSources?.list, null, 4));
    useEffect(() => {
        if (prevCardId.current !== cardId) {
            prevCardId.current = cardId;
            setEffectId(effectIdList()?.[0]?.children?.[0]?.id);
        }
    }, [cardId]);
    const [imgPreviewUri, setImgPreviewUri] = useState(null);

    return (
        <BaseDeckPane
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getListViewData()}
            skipSubCategories={true}
        >
            <Flex
                direction="column"
                justifyContent="left"
                UNSAFE_className="pieChartContainer"
                marginTop="10px"
                padding="10px"
                width={'100%'}
                // width="100vw"
            >
                <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                    <p />
                    How well do your cards work together?
                </span>
                <Divider width="100%" marginTop="10px" size={'S'} />
                {imgPreviewUri && (
                    <div onClick={() => setImgPreviewUri(null)}>
                        <Lightbox
                            medium={''}
                            large={imgPreviewUri}
                            hideDownload={true}
                            hideZoom={true}
                            onClose={() => setImgPreviewUri(null)}
                        />
                    </div>
                )}
                <Flex
                    direction={'column'}
                    flexBasis={300}
                    flexGrow={1}
                    maxWidth={300}
                    flexShrink={3}
                    alignSelf={'center'}
                    marginBottom={30}
                    rowGap={10}
                >
                    {cardId && (
                        <img
                            src={getImageUri()}
                            width={300}
                            style={{ marginTop: 30, marginBottom: 30, alignSelf: 'center' }}
                        />
                    )}
                    <Flex direction={'row'}>
                        <Text minWidth={50} UNSAFE_className={'synergyMenuBoxLabel'}>
                            CARD:
                        </Text>
                        <MenuTrigger>
                            <ActionButton
                                flexBasis={300}
                                flexGrow={1}
                                flexShrink={3}
                                UNSAFE_className={'synergyMenuBoxActionLabel'}
                            >
                                {cards?.[cardId]?.name}
                            </ActionButton>
                            <Menu
                                UNSAFE_className={'synergyMenuBox'}
                                items={cardIdList()}
                                selectedKeys={cardId}
                                onSelectionChange={(evn) => {
                                    setCardId(evn?.currentKey);
                                }}
                                selectionMode="single"
                            >
                                {(item) => (
                                    <Section items={item.children} title={item.name}>
                                        {(item) => <Item>{item.name}</Item>}
                                    </Section>
                                )}
                            </Menu>
                        </MenuTrigger>
                    </Flex>
                    <Flex direction={'row'}>
                        <Text minWidth={50} UNSAFE_className={'synergyMenuBoxLabel'}>
                            EFFECT:
                        </Text>
                        <MenuTrigger>
                            <ActionButton
                                flexBasis={300}
                                flexGrow={1}
                                flexShrink={3}
                                UNSAFE_className={'synergyMenuBoxActionLabel'}
                            >
                                {prettyFormatEffectName()}
                            </ActionButton>
                            <Menu
                                UNSAFE_className={'synergyMenuBox'}
                                items={effectIdList()}
                                selectedKeys={effectId}
                                onSelectionChange={(evn) => {
                                    setEffectId(evn?.currentKey);
                                }}
                                selectionMode="single"
                            >
                                {(item) => (
                                    <Section items={item.children} title={item.name}>
                                        {/*{(item) => getSymbols(item)}*/}
                                        {(item) => <Item>{item.name}</Item>}
                                    </Section>
                                )}
                            </Menu>
                        </MenuTrigger>
                    </Flex>
                    {/*<Text>*/}
                    {/*    {JSON.stringify(deck?.details?.synergy?.list?.[cardId]?.triggers?.[effectId]?.context, null, 4)}*/}
                    {/*</Text>*/}
                </Flex>
            </Flex>
        </BaseDeckPane>
    );
}
