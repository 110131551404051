import React from 'react';
import { Flex, Text } from '@adobe/react-spectrum';
import './FAQ.css';
import { Helmet } from 'react-helmet';
import { FaqPane } from './FaqPane';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function FAQ() {
    return (
        <Flex
            direction="column"
            alignItems="baseline"
            height="100%"
            UNSAFE_style={{ width: 'calc(100dvw - 20px)' }}
            maxWidth="800px"
            gap="size-100"
        >
            <Helmet>
                <title>FAQ</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href="https://www.commandersalt.com/faq" />
                <meta name="description" content="Frequently asked questions" />
                <meta name="twitter:title" content="Commandersalt.com - FAQ" />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content="Commandersalt.com - FAQ" />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content="Frequently asked questions" />
            </Helmet>
            <BreadCrumbs />
            <h3>Hello, and thanks for checking out this site :)</h3>
            <Text>
                The site currently is setup to try and give meaningful data and numbers to quantify the 'what power
                level are we all playing' question... I am personally pretty tired of having that whole talk before
                every game lol.
            </Text>
            <Text>Beyond that... hopefully whatever question you may have is answered below!</Text>
            <h1>General</h1>
            <FaqPane title="Who built this?">
                <Text>
                    I've been playing MtG off and on since Revised came out in 1993. I used to play a lot of competitive
                    legacy and modern, but I primarily do Commander nowadays for the social interaction.
                </Text>
                <Text>For my day job, I am a senior software engineer at a large tech firm.</Text>
                <Text>
                    I played a lot of Legacy and Modern competitively, but between work and being a dad, my available
                    time to keep up on meta shifts and the like has gone down considerably. I play commander pretty
                    exclusively nowadays for the social interaction.
                </Text>
                <Text>
                    My decklists are <a href="https://www.moxfield.com/users/grumbledore">available on Moxfield</a>, and
                    <a href="https://commandersalt.com/#/authors/id/grumbledore">here</a>.
                </Text>
                <Text>
                    My play style is primarily stax and control, which honestly probably leads to some sampling bias in
                    the calculations, but I do strive to be objective 😂.
                </Text>
            </FaqPane>
            <FaqPane title="How did this start?">
                <Text>
                    The idea for this site started because my core playgroup enjoyed making our decks as salty as we
                    could. Naturally, this turned in to a competition.
                </Text>
                <Text>
                    As the competition evolved, the question of how to fully quantify a deck's salt level became more
                    and more of a question. We were, of course, intimately familiar with edhrec.com's famous salt list.
                    In fact, a couple of us used it as a deck building tool. Given that - the solution was fairly
                    obvious. I made a node script that aggregated a deck's overall salt score based on the scores of
                    it's constituent cards.
                </Text>
                <Text>
                    We showed this to a few other people that we played with in our lgs, and quickly realized that the
                    idea was amusing enough that other people wanted to participate.
                </Text>
                <Text>
                    Thus... <i>this</i>.
                </Text>
            </FaqPane>
            <FaqPane title="Why would I use this?">
                <Text>
                    Although the site originally started as a joke in my playgroup, it now serves as a tool to qualify
                    the overall power level of a deck.
                </Text>
                <Text>
                    Commander as a format is difficult to estimate power levels. This is due to the type of players it
                    attracts, the lack of a competitive meta that is tracked by Wizards, and the overall (very)
                    subjective threat assessment of players.
                </Text>
                <Text>
                    Given this, I try to rank the decks by three main categories:
                    <ul>
                        <li>
                            <b>Salt:</b> how aggravating is a deck to other players
                        </li>
                        <li>
                            <b>Threat Assessment:</b> this is intended to quantify how much other players at the table
                            might assess your deck's power level based on the relative attributes of the cards in the
                            deck. Drawing cards, ramping, stax, counters... You get the idea :)
                        </li>
                        <li>
                            <b>Combo:</b> this more or less indicates how much your deck is trying to win. Pretty much
                            every cedh deck on earth has some game winning combo in it. You could also look at this as
                            the 'try hard meter'.
                        </li>
                    </ul>
                </Text>
            </FaqPane>
            <FaqPane title="How do you make money with this?">
                <Text>The short answer is that I don't, and I never intend to.</Text>
                <Text>
                    The MtG community has been amazing for me in life - many of my friends were met playing this game.
                    As cliche as its... I want to give back to the community.If this site is even remotely useful to
                    somebody in the world, then it has served it's purpose.
                </Text>
            </FaqPane>
            <FaqPane title="Why don't you show card and deck prices?">
                <Text>
                    The one thing I don't like about Commander is the financial gatekeeping; I don't want to contribute
                    to it.
                </Text>
            </FaqPane>
            <h1>Scoring</h1>
            NOTE: the scoring system has evolved quite a bit since I originally made this FAQ, and I have not had the
            time to circle back and describe how everything works. I hope to do that soon 😃. In the meantime, feel free
            to join the discord and ask any questions you may have!
            {/*<FaqPane title="How do you generate Saltiness scores?">*/}
            {/*    <Text>*/}
            {/*        Salt scores are aggregated from the community driven scores on edhrec.com. Additional points are*/}
            {/*        awarded for things like stax pieces, which aggravate everyone equally.*/}
            {/*    </Text>*/}
            {/*</FaqPane>*/}
            {/*<FaqPane title="How do you generate Threat scores?">*/}
            {/*    <Text>*/}
            {/*        Threat scores are basically a measure of 'how many things does this card do'. A vanilla creature is*/}
            {/*        0. Deathrite Shaman is 3.*/}
            {/*    </Text>*/}
            {/*</FaqPane>*/}
            {/*<FaqPane title="How do you generate Combo scores?">*/}
            {/*    <Text>*/}
            {/*        I have tried a few ways of calculating combo scores. The current algorithm looks at the following:*/}
            {/*    </Text>*/}
            {/*    <ul>*/}
            {/*        <li>Total generic mana cost</li>*/}
            {/*        <li>Density of pips for each color</li>*/}
            {/*        <li>The end result conditions of the combo</li>*/}
            {/*    </ul>*/}
            {/*</FaqPane>*/}
            {/*<FaqPane title="What does the Overall score represent?">*/}
            {/*    <Text>The overall score is just the sum of salt, threat, and combo scores. </Text>*/}
            {/*</FaqPane>*/}
            {/*<FaqPane title="How do you calculate the Grades?">*/}
            {/*    <Text>*/}
            {/*        Grades are based on somewhat arbitrary thresholds that I set based on the deck data available to me.*/}
            {/*    </Text>*/}
            {/*</FaqPane>*/}
            {/*<FaqPane title="What does the Relative Power Level represent?">*/}
            {/*    <Text>This attempts to answer the 'what power level is your deck' question.</Text>*/}
            {/*</FaqPane>*/}
            {/*<FaqPane title="Do card and deck prices affect scoring?">*/}
            {/*    <Text>Nope.</Text>*/}
            {/*</FaqPane>*/}
            <h1>I have complaints!</h1>
            <FaqPane title="I disagree with your card categories. What do I do?">
                <Text>
                    The card categories are fairly difficult to derive, which makes it more or less an everlasting work
                    in progress.
                </Text>
                <Text>
                    That being said, if you see something egregious, please let me know via the form in the Contact
                    page.
                </Text>
            </FaqPane>
            <FaqPane title="My deck is definitely not a 4! I feel it is at least an 8. What do I do?">
                <Text>
                    Again, these are based on arbitrary thresholds that I have set. If it feels off, please feel free to
                    reach out to me via the form in the Contact page.
                </Text>
            </FaqPane>
        </Flex>
    );
}
