import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, Sector, ResponsiveContainer } from 'recharts';
import React, { useState } from 'react';
import { Flex } from '@adobe/react-spectrum';
import { BaseDeckPane } from './BaseDeckPane';
import isMobile from 'ismobilejs';

export function CategoriesPane(props) {
    const cards = useSelector((state) => state.deck.cards);

    const getPrettyLabel = (label) => {
        label = label.toLowerCase();
        // label = label === 'tutors' ? 'tutor' : label;
        // label = label === 'landbase' ? 'manabase' : label;
        // label = label === 'winConditions' ? 'win cons' : label;
        // label = label === 'fastmana' ? 'fast mana' : label;
        // label = label === 'combos' ? 'combo' : label;
        // label = label === 'answers' ? 'interaction' : label;
        // label = label === 'wincon' ? 'other' : label;

        label = label === 'spotremoval' ? 'spot removal' : label;
        label = label === 'cantrip' ? 'draw' : label;
        label = label === 'counters' ? 'counterspells' : label;
        label = label === 'plusoneplusonecounters' ? '+1/+1 COUNTERS' : label;
        label = label === 'extracombat' ? 'EXTRA COMBAT' : label;

        // console.log(label)
        label = label.toUpperCase();

        return label;
    };

    const getCategories = () => {
        const list = {};

        Object.keys(cards || {})?.map((id) => {
            const card = cards[id];
            Object.keys(card?.categories?.stats || {}).forEach((cat) => {
                switch (cat) {
                    case 'slow':
                        break;
                    default:
                        if (!list.hasOwnProperty(cat)) {
                            list[cat] = {
                                label: getPrettyLabel(cat),
                                id: cat,
                                count: 0,
                                list: [],
                                color: `#FFFFFF`,
                            };
                        }

                        list[cat].list.push({ ...card });
                        list[cat].count += 1;
                }
            });
        });

        return list;
    };

    const debug = getCategories();

    return (
        <BaseDeckPane
            title={'Categories'}
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getCategories()}
            skipSubCategories={true}
        ></BaseDeckPane>
    );
}
